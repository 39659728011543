import React from 'react';
import { SpeechBubble } from '../SpeechBubble';
import { CardsContainer, CardSearchSkill, LinkTitle, Text } from './styled';
import { formatText } from '../../../../../commons/js/utils';
import { MessageText } from '../SpeechBubble/styled';
import { GlobeStylesType } from '../../../ChatboxRoot/types';

type CardSearchSkillRenderPropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  selectOption: (value: string) => void;
  header: string;
  results: Array<{
    url: string;
    title: string;
    body: string;
  }>;
};

export const CardSearchSkillRender: React.FC<CardSearchSkillRenderPropsType> = (props) => {
  return (
    <CardsContainer>
      {props.header ? (
        <SpeechBubble currentStyles={props.currentStyles} initialStyles={props.initialStyles}>
          <MessageText>{formatText(props.header)}</MessageText>
        </SpeechBubble>
      ) : (
        ''
      )}
      {props.results.map((cardSearch, index) => (
        <CardSearchSkill
          boxShadow={props.currentStyles.boxShadow ? props.currentStyles.boxShadow : props.initialStyles.boxShadow}
          backgroundColor={props.currentStyles.backgroundColor ? props.currentStyles.backgroundColor : props.initialStyles.backgroundColor}
        >
          <LinkTitle
            color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
            fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
            key={index}
            href={cardSearch.url}
            target="_blank"
          >
            {formatText(cardSearch.title)}
          </LinkTitle>
          <Text
            color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
            fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
          >
            {cardSearch.body.substring(0, 200).replace('\n\n', ' ') + '...'}
          </Text>
        </CardSearchSkill>
      ))}
    </CardsContainer>
  );
};
