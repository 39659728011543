import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../../commons/images/svg/icons/cross-white.svg';

export const BaseScreen = styled.div`
  background-color: rgb(240, 240, 240);
  height: 100%;
  text-align: center;
  padding-top: 30%;
`;

export const BottomMessage = styled.div`
  position: absolute;
  bottom: 15px;
  padding: 10px;
  background-color: rgb(151, 151, 151);
  text-align: left;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
`;

export const CircleClose = styled(CrossIcon)`
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  padding: 3px;
  border-radius: 50%;
  float: right;
`;

export const Title = styled.span`
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  vertical-align: middle;
  margin-right: 5px;
`;

export const Description = styled.span`
  font-size: 14px;
  color: #fff;
  vertical-align: middle;
`;

export const MessageBackground = styled.div`
  width: 115px;
  background-color: rgb(151, 151, 151);
  position: absolute;
  bottom: 57px;
  padding: 3px;
  text-align: right;
  right: 0;
  border-radius: 10px 0 0 10px;
`;

export const IconMessage = styled.div`
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
  padding-right: 10px;
`;

export const MessageIcon = styled(CrossIcon)`
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  padding: 3px;
  border-radius: 50%;
  float: left;

  g:nth-child(1) path {
    fill: #fff !important;
  }
`;
