import { HTMLAttributeAnchorTarget } from 'react';
import { BaseWriteSectionStylesType } from '../ChatboxModules/WriteSection/WriteSection';

declare global {
  interface Window {
    chatbot: {
      updateChatFrameWidth?: (newValue: string) => void;
      updateChatFrameHeight?: (newValue: string) => void;
      updateChatFrameAlign?: (newValue: string) => void;
      updateChatIconStyles?: (userStyles: ChatIconStylesType) => void;
      updateChatIconText?: (newName: string) => void;
      updateChatIconImage?: (newUrl: string) => void;
      updateChatIconShape?: (newShape: ChatIconShapeType) => void;
      updateChatTopStyles?: (userStyles: ChatTopStylesType) => void;
      updateChatTopName?: (newName: string) => void;
      updateChatContactPicture?: (imgUrl: string) => void;
      updateMessagesSectionStyles?: (newStyles: { backgroundColor: string; fontFamily: string }) => void;
      updateUserGlobeStyles?: (newStyles: GlobeStylesType) => void;
      updateBotGlobeStyles?: (newStyles: GlobeStylesType) => void;
      updateSendButtonStyles?: (userStyles: SendButtonStylesType) => void;
      updateSendIconImage?: (newUrl: string) => void;
      updateWriteSectionStyles?: (userStyles: BaseWriteSectionStylesType) => void;
      updatePlacerholderText?: (newText: string) => void;
      openChat?: () => void;
      closeChat?: () => void;
    };
  }
}

/* Widget Types */
export enum WidgetTypes {
  ELEGANT = 'elegant',
}

export type WidgetTypesType = undefined | WidgetTypes;

export enum ChatTopMenuActionsType {
  URL = 'url',
  CLEAR_CHAT = 'clearChat',
}

export type ChatTopMenuItem = {
  label: string;
  action: ChatTopMenuActionsType;
  url?: string;
};

export type ChatTopMenuType = Array<ChatTopMenuItem>;

/* Chat config type */
export type ChatConfigType = {
  chatIcon: {
    text: string;
    iconImage: {
      public_id: string;
      secure_url: string;
    };
  };
  chatTop: {
    name: string;
    avatar: {
      public_id: string;
      secure_url: string;
    };
    menu?: ChatTopMenuType;
  };
  writeSection: {
    placeholderText: string;
    sendButton: {
      secure_url: string;
    };
  };
  messagesSection: {
    contentFormat: string;
  };
};

/* Socket Server type */
export type SocketServerType = {
  url: string;
  endedWord: string;
};

/* Chat style types */
export type ChatFrameStylesType = {
  alignFrame: string;
  width: string;
  height: string;
  zIndex: string;
};

export type ChatIconStylesType = {
  alignIcon: string;
  alwaysVisible: string;
  backgroundColor: string;
  borderBottomRightRadius: string;
  borderBottomLeftRadius: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  boxShadow: string;
  color: string;
  fontFamily: string;
  fontSize: string;
  iconColor1: string;
  iconColor2: string;
  iconColor3: string;
  iconColor4: string;
  marginLeft: string;
  marginRight: string;
  verticalAlign: string;
};

export type ChatTopStylesType = {
  avatarBorderRadius: string;
  backgroundColor: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomRightRadius: string;
  borderBottomLeftRadius: string;
  boxShadow: string;
  buttonBackgroundColor: string;
  buttonBackgroundHoverColor: string;
  buttonColor: string;
  buttonHoverColor: string;
  color: string;
  fontFamily: string;
  fontSize: string;
  textAlign: string;
};

export type GlobeStylesType = {
  backgroundColor: string;
  borderBottomRightRadius: string;
  borderBottomLeftRadius: string;
  borderColor: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  boxShadow: string;
  color: string;
  fontSize: string;
  hideName: boolean | string;
  hideTime: boolean | string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  peakPosition: string;
  textAlign: string;
  textShadow: string;
  //Components:
  linkOutSuggestion?: LinkOutSuggestionStylesType;
  chips?: ChipsStylesType;
};

export type LinkOutSuggestionStylesType = {
  backgroundColor: string;
  border: string;
  borderColor: string;
  boxShadow: string;
  color: string;
  fontSize: string;
  target: HTMLAttributeAnchorTarget;
  textShadow: string;
  hoverOpacity: string;
};

export type ChipsStylesType = {
  backgroundColor: string;
  borderBottomLeftRadius: string;
  borderBottomRightRadius: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  boxShadow: string;
  color: string;
  fontSize: string;
  hoverOpacity: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  marginTop: string;
  textAlign: string;
  transition: string;
};

export type MessagesSectionStylesType = {
  backgroundColor: string;
  botGlobe: GlobeStylesType;
  fontFamily: string;
  userGlobe: GlobeStylesType;
};

export type SendButtonStylesType = {
  backgroundColor: string;
  borderColor: string;
  borderWidth: string;
  hoverBackgroundColor: string;
  hoverBorderColor: string;
  hoverIconColor: string;
  iconColor: string;
};

export type WriteSectionStylesType = {
  backgroundColor: string;
  boxShadow: string;
  color: string;
  fontFamily: string;
  fontSize: string;
  placeholderColor: string;
  sendButton: SendButtonStylesType;
};

export type StylesType = {
  chatFrame: ChatFrameStylesType;
  chatIcon: ChatIconStylesType;
  chatTop: ChatTopStylesType;
  messagesSection: MessagesSectionStylesType;
  writeSection: WriteSectionStylesType;
};

/* Messages types */

export enum ResponseTypes {
  PAUSE = 'pause',
  TEXT = 'text',
  CARRUSEL_SELECT = 'carouselSelect',
  SUGGESTIONS = 'suggestions',
  BASIC_CARD = 'basicCard',
  LIST_SELECT = 'listSelect',
  LINK_OUT_SUGGESTION = 'linkOutSuggestion',
  OPTION = 'option',
}

export type TextMessageType = {
  response_type: ResponseTypes.TEXT;
  text: string;
  mine: boolean;
};

export type CarrouselMessageType = {
  items: Array<BasicCardMessageType>;
  description: string;
  title: string;
};

export type SuggestionsMessageType = {
  Options: Array<string>;
  title: string;
};

export type BasicCardMessageType = {
  title: string;
  subtitle?: string;
  formattedText?: string;
  image: {
    accessibilityText: string;
    imageUri: string;
    modal: boolean;
  };
  description: string;
  info: {
    synonyms: Array<string>;
    key: string;
    uri: string | null;
  };
  buttonsOptions: Array<{ text: string; value: string }>;
  custom?: string;
  buttons?: Array<{
    openUriAction: {
      uri: string;
    };
    title: string;
  }>;
};

export type ListSelectMessageType = {
  title: string;
  items: Array<{
    title: string;
    description: string;
    image: {
      imageUri: string;
      accessibilityText: string;
    };
    info: {
      key: string;
    };
  }>;
};

export type LinkOutSuggestionMessageType = {
  destinationName: string;
  uri: string;
};

export type ImageMessageType = {
  title: string;
  description: string;
  source: string;
};

export type OptionMessageType = {
  title: 'string';
  options: Array<{
    label: string;
    value: {
      input: {
        text: string;
      };
    };
  }>;
};

export type MessageType = {
  mine: boolean;
  response_type: ResponseTypes;
  text?: string;
  carouselSelect?: CarrouselMessageType;
  suggestions?: SuggestionsMessageType;
  basicCard?: BasicCardMessageType;
  listSelect?: ListSelectMessageType;
  linkOutSuggestion?: LinkOutSuggestionMessageType;
  image?: ImageMessageType;
  option?: OptionMessageType;
  //refactor SkillSearch
  header?: string;
  results?: Array<{
    url: string;
    title: string;
    body: string;
  }>;
};

export enum ChatIconShapeType {
  CIRCLE = 'circle',
  DIAMOND = 'diamond',
  SQUARE = 'square',
}

export enum ChatIconSizeType {
  SMALL = 'small',
  NORMAL = 'normal',
  BIG = 'big',
}

export enum WidgetAlign {
  RIGHT = 'right',
  LEFT = 'left',
}

export type ExternalConfigType = {
  hideHeader?: boolean;
  startOpened?: boolean;
  userName?: string;
};
