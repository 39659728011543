import styled from 'styled-components';

export const ListContainer = styled.div<{ boxShadow: string; fontSize: string }>`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  box-shadow: ${(props) => props.boxShadow};
  font-family: inherit;
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
`;

export const TitleContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
  border: 1px solid ${(props) => props.backgroundColor};
`;

export const Title = styled.div`
  text-align: left;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
`;

export const ListItem = styled.div`
  display: block;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

export const ItemImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
  margin-right: 10px;
`;

export const ItemText = styled.div`
  color: #666;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
`;

export const ItemTitle = styled.div`
  margin: 0 0 5px 0;
  font-weight: bold;
  font-size: inherit;
  font-family: inherit;
  color: #000;
`;

export const ItemDescription = styled.div`
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  color: #000;
`;
