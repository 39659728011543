import React from 'react';
import { Tag } from './styled';

type AnswerTagPropsType = {
  text: string;
  onClickHandler: (text: string) => void;
};

export const AnswerTag: React.FC<AnswerTagPropsType> = (props) => {
  return (
    <Tag
      onClick={() => {
        props.onClickHandler(props.text);
      }}
    >
      {props.text}
    </Tag>
  );
};
