import styled from 'styled-components';

export const CardContainer = styled.div<{ fontSize: string }>`
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: ${(props) => props.fontSize};
  margin-bottom: 12px;
  font-family: inherit;
`;

export const ImageSection = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const TitleContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 2px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  color: #fff;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  margin: 0;
  line-height: 1;
`;

export const Subtitle = styled.div`
  color: #fff;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  margin: 0;
  line-height: 1;
`;

export const ContentContainer = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const ContentText = styled.div`
  color: #333;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
`;

export const LinkContainer = styled.div`
  cursor: pointer;
  background-color: #fff;
  padding: 10px 20px;
`;

export const LinkText = styled.a`
  color: #333;
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    margin-right: 8px;
  }
`;

export const CardButton = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px;
  background-color: #fff;
  cursor: pointer;
  color: #38b4e0;
`;

export const CardButtonContainer = styled.div`
  border: 0, 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  ${CardButton} {
    &:last-child {
      border-bottom: 0;
    }
  }
`;
