import React, { useEffect, useState } from 'react';
import { SendButton } from './SendButton/SendButton';
import { AnswerComponent } from './AnswerComponent/AnswerComponent';
import { TextField, TrialMessage, WriteSectionSectionContainer } from './styled';
import { isColorLight } from '../../../../commons/js/utils';
import { WriteSectionStylesType } from '../../ChatboxRoot/types';

type WriteSectionPropsType = {
  initialStyles: WriteSectionStylesType;
  sendMessage: (message: string, hidden?: boolean) => void;
  placeholderText: string;
  sendIconImage: string;
  isFreeVersion: boolean;
  chatId: string;
};

export type BaseWriteSectionStylesType = Omit<WriteSectionStylesType, 'sendButton'>;

export const WriteSection: React.FC<WriteSectionPropsType> = (props) => {
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [placeholderText, setPlaceholderText] = useState<string>('');
  const [newStyles, setNewStyles] = useState<BaseWriteSectionStylesType>({
    backgroundColor: '',
    color: '',
    fontSize: '',
    fontFamily: '',
    boxShadow: '',
    placeholderColor: '',
  });

  useEffect(() => {
    window.chatbot.updateWriteSectionStyles = (userStyles: BaseWriteSectionStylesType) => {
      setNewStyles({ ...newStyles, ...userStyles });
    };

    window.chatbot.updatePlacerholderText = (newText: string) => {
      setPlaceholderText(newText);
    };
  }, [newStyles]);

  const validateKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      props.sendMessage(currentMessage);
      setCurrentMessage('');
    }
  };

  const clickTag = (text: string) => {
    props.sendMessage(text);
    setCurrentMessage('');
  };

  const handleTextMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentMessage(event.target.value);
  };

  const sendButtonHandler = () => {
    props.sendMessage(currentMessage);
    setCurrentMessage('');
  };

  const { backgroundColor, fontSize, color, fontFamily, boxShadow, placeholderColor } = props.initialStyles;
  const writeSectionBackgroundColor = newStyles.backgroundColor ? newStyles.backgroundColor : backgroundColor;

  return (
    <WriteSectionSectionContainer
      backgroundColor={writeSectionBackgroundColor}
      color={newStyles.color ? newStyles.color : color}
      fontSize={newStyles.fontSize ? newStyles.fontSize : fontSize}
      fontFamily={newStyles.fontFamily ? newStyles.fontFamily : fontFamily}
      boxShadow={newStyles.boxShadow ? newStyles.boxShadow : boxShadow}
      placeholderColor={newStyles.placeholderColor ? newStyles.placeholderColor : placeholderColor}
      className="write-container"
    >
      <TextField
        onChange={(e) => handleTextMessage(e)}
        value={currentMessage}
        placeholder={placeholderText ? placeholderText : props.placeholderText}
        onKeyDown={(e: React.KeyboardEvent) => {
          validateKey(e);
        }}
      />
      <SendButton initialStyles={props.initialStyles.sendButton} onClickHandler={sendButtonHandler} sendIconImage={props.sendIconImage} />
      <AnswerComponent /*options={availableOptions}*/ clickTag={clickTag} />
      {props.isFreeVersion && (
        <TrialMessage>
          <a
            href={'https://www.blahbox.net/?utm_campaign=' + props.chatId + '&utm_source=powerby&utm_medium=referral'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Powered By Blahbox"
              src={
                isColorLight(writeSectionBackgroundColor)
                  ? 'https://res.cloudinary.com/blahbox/image/upload/v1586984487/commons/poweredFondoClaro.png'
                  : 'https://res.cloudinary.com/blahbox/image/upload/v1586984487/commons/poweredFondoOscuro.png'
              }
            />
          </a>
        </TrialMessage>
      )}
    </WriteSectionSectionContainer>
  );
};
