import axios from 'axios';
import { CORE_BASE_URL, MIDDLE_BASE_URL } from '../../commons/js/constants';
import { ChatConfigType, MessageType, StylesType, SocketServerType, WidgetTypesType } from '../../components/Chatbox/ChatboxRoot/types';

type StylesResponseType = {
  status: number;
  data: {
    data: {
      styles: StylesType;
      config: ChatConfigType;
      socketServer: SocketServerType;
      wakeupWord: string;
      name: string;
      analyticsTrackID: string;
      subscription: string;
      sub: string;
      widgetType: WidgetTypesType;
    };
  };
};

export type SubscriptionResponseType = {
  status: number;
  data: {
    sub: {
      Enable: boolean;
      ProductCode: string;
    };
  };
};

export type SendMessageResponse = {
  status: number;
  data: {
    data: {};
  };
};

export type ContextType = {
  conversation_id: string;
  userParams?: Object;
};

export type GetContextResponse = {
  status: number;
  data: {
    data: {
      context: ContextType;
      output: {
        generic: Array<MessageType>;
      };
    };
  };
};

export type ResponseType<T> = {
  success: boolean;
  data: T;
  error: string;
};

export function chatbotService() {
  return {
    chatId: '',
    previewMode: false,
    axiosClient: axios.create({}),

    init(token: string, chatId: string, previewMode: boolean) {
      console.log('INIT CHATBOX SERVICE!!!!');
      console.log(chatId);

      this.chatId = chatId;
      this.previewMode = previewMode;

      this.axiosClient = axios.create({
        headers: {
          Authorization: token,
          //@ts-ignore
          'X-IBM-Client-Id': process.env.MIDDLEAPIKEY,
          //@ts-ignore
          'x-core-client-id': process.env.COREAPIKEY,
        },
      });
    },
    async getStyles() {
      //console.log(CORE_BASE_URL);
      //console.log(MIDDLE_BASE_URL);

      try {
        const baseUrl = this.previewMode ? CORE_BASE_URL : MIDDLE_BASE_URL;
        const response: StylesResponseType = await this.axiosClient.get(baseUrl + 'chat/getChat/' + this.chatId);

        if (response.status !== 200) {
          throw new Error('Error fetching styles');
        }

        return { success: true, data: response.data.data };
      } catch (error) {
        return {
          success: false,
          error: 'Error fetching the styles',
        };
      }
    },
    async getSubscription(subscription: string) {
      try {
        const response: SubscriptionResponseType = await this.axiosClient.get(MIDDLE_BASE_URL + 'chat/getSubs/' + subscription);

        if (response.status !== 200) {
          throw new Error('Error fetching the subscription');
        }

        return { success: true, data: response.data.sub };
      } catch (error) {
        return {
          success: false,
          error: 'Error fetching the subscription',
        };
      }
    },
    async getContext(wakeupWord: string, chatId: string, userParams?: Object) {
      try {
        const config = {
          config: {
            previewMode: this.previewMode,
          },
          input: {
            text: wakeupWord,
          },
          context: {
            userParams,
          },
        };

        //console.log('INSIDE THE CONTEXT');
        //console.log(chatId);
        //console.log(config);

        const response: GetContextResponse = await this.axiosClient.post(MIDDLE_BASE_URL + 'chat/message/send?chatId=' + chatId, config);

        if (response.status !== 200) {
          throw new Error('Error fetching embedded urls');
        }

        return { success: true, data: response.data.data };
      } catch (error) {
        return {
          success: false,
          data: undefined,
          error: 'error',
        };
      }
    },
    async sendMessage(message: string, currentContext: ContextType) {
      const config = {
        config: {
          previewMode: this.previewMode,
        },
        input: {
          text: message,
        },
        context: currentContext,
      };

      try {
        const response: SendMessageResponse = await this.axiosClient.post(
          MIDDLE_BASE_URL + 'chat/message/send/?chatId=' + this.chatId,
          config,
          {
            //@ts-ignore
            headers: { 'X-IBM-Client-Id': process.env.MIDDLEAPIKEY },
          },
        );

        if (response.status !== 200) {
          throw new Error('Error fetching the subscription');
        }

        return { success: true, data: response.data.data };
      } catch (error) {
        return {
          success: false,
          data: error,
          error: 'Could not send the meesage request',
        };
      }
    },
  };
}

export const blahboxService = chatbotService();
