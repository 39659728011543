import React from 'react';
import { ToolboxContainer, ToolButton } from './styled';

type ToolboxPropsType = {
  onClear: () => void;
};

export const Toolbox: React.FC<ToolboxPropsType> = (props) => {
  return (
    <ToolboxContainer>
      <ToolButton onClick={() => props.onClear()}>Clear</ToolButton>
    </ToolboxContainer>
  );
};
