import styled from 'styled-components';
import { ChatTopStylesType } from '../../ChatboxRoot/types';

type ChatTopContainerStylesType = Omit<
  ChatTopStylesType,
  'buttonColor' | 'buttonHoverColor' | 'buttonBackgroundColor' | 'buttonBackgroundHoverColor'
>;

export const ChatTopContainer = styled.div<ChatTopContainerStylesType>`
  padding: 14px 65px 0 90px;
  position: relative;
  z-index: 10;
  min-height: 47px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  font-family: ${(props) => props.fontFamily};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
  box-shadow: ${(props) => props.boxShadow};
  img,
  svg {
    border-radius: ${(props) => props.avatarBorderRadius};
  }
`;

export const CloseButton = styled.div<{
  buttonBackgroundColor: string;
  buttonColor: string;
  buttonBackgroundHoverColor: string;
  buttonHoverColor: string;
}>`
  border-radius: 30px;
  -webkit-appearance: none;
  border: 0;
  transition: all 0.2s ease 0s;
  width: 34px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  text-align: center;
  background-color: ${(props) => props.buttonBackgroundColor};

  svg {
    width: 15px;
    height: 15px;
  }

  path {
    transition: all 0.2s ease 0s;
    fill: ${(props) => props.buttonColor};
  }

  &:hover {
    width: 50px;
    border-radius: 20px;
    background-color: ${(props) => props.buttonBackgroundHoverColor};
    box-sizing: border-box;
    path {
      fill: ${(props) => props.buttonHoverColor};
    }
  }
`;

export const ContactPicture = styled.div`
  position: absolute;
  left: 20px;
  top: -7px;

  img,
  svg {
    width: 65px;
    height: auto;
  }
`;
export const Status = styled.div`
  font-size: 12px;
  position: relative;
  top: 4px;
  overflow: hidden;
  height: 15px;
  width: 44px;
  img {
    width: 105px;
    left: -42px;
    top: -44px;
    position: relative;
  }
`;

export const Controls = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
`;
