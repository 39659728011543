import React, { useState } from 'react';
import { Container, CloseIcon } from './styled';
import { AnswerTag } from './AnswerTag/AnswerTag';
import { ReactSVG } from 'react-svg';
import CrossIcon from '../../../../../commons/images/svg/icons/cross-white.svg';

type AnswerComponentProps = {
  clickTag: (text: string) => void;
  //to review
  options?: Array<string>;
};

export const AnswerComponent: React.FC<AnswerComponentProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const closeComponent = () => {
    setIsOpen(false);
  };

  const ClickTagHandler = (text: string) => {
    closeComponent();
    props.clickTag(text);
  };

  return (
    <>
      {props.options ? (
        <Container hide={!isOpen}>
          {props.options.map((answerText: string) => {
            return <AnswerTag key={answerText} onClickHandler={ClickTagHandler} text={answerText} />;
          })}
          <CloseIcon onClick={closeComponent}>
            <ReactSVG src={CrossIcon} wrapper="span" />
          </CloseIcon>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};
