import React, { useEffect, useState } from "react";
import { LoadImage } from "../../../LoadImage/LoadImage";
import {
  ChatTopContainer,
  CloseButton,
  Controls,
  Logo,
  MenuItemStyled,
  TopMenuStyled,
  TopMenuContentStyled,
} from "./styled";
import {
  ChatTopMenuActionsType,
  ChatTopMenuItem,
  ChatTopMenuType,
  ChatTopStylesType,
} from "../../ChatboxRoot/types";
import { DotsMenuIcon } from "../../../../commons/icons/dots-menu";

type ChatTopElegantPropsType = {
  initialStyles: ChatTopStylesType;
  logo: string;
  toggleChatWindow: (open: boolean) => void;
  topMenu?: ChatTopMenuType;
  clearChat: () => void;
  openTopMenu: boolean;
  setOpenTopMenu: (open: boolean) => void;
};

export const ChatTopElegant: React.FC<ChatTopElegantPropsType> = (props) => {
  const [logo, setLogo] = useState<string>("");
  const [newStyles, setNewStyles] = useState<ChatTopStylesType>({
    backgroundColor: "",
    fontFamily: "",
    textAlign: "",
    color: "",
    fontSize: "",
    avatarBorderRadius: "",
    boxShadow: "",
    buttonColor: "",
    buttonHoverColor: "",
    buttonBackgroundColor: "",
    buttonBackgroundHoverColor: "",
    borderTopLeftRadius: "",
    borderTopRightRadius: "",
    borderBottomRightRadius: "",
    borderBottomLeftRadius: "",
  });

  useEffect(() => {
    window.chatbot.updateChatTopStyles = (userStyles: ChatTopStylesType) => {
      setNewStyles({ ...newStyles, ...userStyles });
    };
    window.chatbot.updateChatContactPicture = (imgUrl: string) => {
      setLogo(imgUrl);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topMenuClick = (item: ChatTopMenuItem) => {
    if (item.action === ChatTopMenuActionsType.URL && item.url) {
      window.open(item.url);
    } else if (item.action === ChatTopMenuActionsType.CLEAR_CHAT) {
      props.clearChat();
    }
  };

  const {
    fontFamily,
    backgroundColor,
    textAlign,
    color,
    fontSize,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    boxShadow,
    buttonColor,
    buttonHoverColor,
    buttonBackgroundColor,
    buttonBackgroundHoverColor,
  } = props.initialStyles;

  return (
    <ChatTopContainer
      fontFamily={newStyles.fontFamily ? newStyles.fontFamily : fontFamily}
      backgroundColor={
        newStyles.backgroundColor ? newStyles.backgroundColor : backgroundColor
      }
      textAlign={newStyles.textAlign ? newStyles.textAlign : textAlign}
      color={newStyles.color ? newStyles.color : color}
      fontSize={newStyles.fontSize ? newStyles.fontSize : fontSize}
      borderTopLeftRadius={
        newStyles.borderTopLeftRadius
          ? newStyles.borderTopLeftRadius
          : borderTopLeftRadius
      }
      borderTopRightRadius={
        newStyles.borderTopRightRadius
          ? newStyles.borderTopRightRadius
          : borderTopRightRadius
      }
      borderBottomRightRadius={
        newStyles.borderBottomRightRadius
          ? newStyles.borderBottomRightRadius
          : borderBottomRightRadius
      }
      borderBottomLeftRadius={
        newStyles.borderBottomLeftRadius
          ? newStyles.borderBottomLeftRadius
          : borderBottomLeftRadius
      }
      boxShadow={newStyles.boxShadow ? newStyles.boxShadow : boxShadow}
    >
      {props.topMenu && (
        <TopMenuStyled onClick={() => props.setOpenTopMenu(!props.openTopMenu)}>
          <DotsMenuIcon />
          {props.openTopMenu && (
            <TopMenuContentStyled>
              <ul>
                {props.topMenu.map((item) => (
                  <MenuItemStyled onClick={() => topMenuClick(item)}>
                    {item.label}
                  </MenuItemStyled>
                ))}
              </ul>
            </TopMenuContentStyled>
          )}
        </TopMenuStyled>
      )}
      <Logo>
        <LoadImage src={logo ? logo : props.logo} wrapper="span" alt="logo" />
      </Logo>
      <Controls>
        <CloseButton
          buttonColor={
            newStyles.buttonColor ? newStyles.buttonColor : buttonColor
          }
          buttonHoverColor={
            newStyles.buttonHoverColor
              ? newStyles.buttonHoverColor
              : buttonHoverColor
          }
          buttonBackgroundColor={
            newStyles.buttonBackgroundColor
              ? newStyles.buttonBackgroundColor
              : buttonBackgroundColor
          }
          buttonBackgroundHoverColor={
            newStyles.buttonBackgroundHoverColor
              ? newStyles.buttonBackgroundHoverColor
              : buttonBackgroundHoverColor
          }
          onClick={(e) => {
            props.toggleChatWindow(false);
          }}
        >
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="64px"
            height="64px"
            viewBox="0 0 64 64"
          >
            <g>
              <path
                fill="#FFFFFF"
                d="M28.941,31.786L0.613,60.114c-0.787,0.786-0.787,2.062,0,2.849c0.393,0.395,0.909,0.59,1.424,0.59
																		c0.516,0,1.031-0.195,1.424-0.59l28.541-28.541l28.541,28.541c0.395,0.395,0.909,0.59,1.424,0.59c0.516,0,1.031-0.195,1.424-0.59
																		c0.787-0.787,0.787-2.063,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.063-0.786-2.848,0
																		L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"
              />
            </g>
          </svg>
        </CloseButton>
      </Controls>
    </ChatTopContainer>
  );
};
