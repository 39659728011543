import React from 'react';
import { ImageContainer, ImageText } from './styled';
import { SpeechBubble } from '../SpeechBubble';
import { GlobeStylesType, ImageMessageType } from '../../../ChatboxRoot/types';

type ImageRenderPropsType = {
  image: ImageMessageType;
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  updateModalContent: (imageUrl: string, altText?: string) => void;
  toggleModal: () => void;
};

export const ImageRender: React.FC<ImageRenderPropsType> = (props) => {
  return (
    <SpeechBubble currentStyles={props.currentStyles} initialStyles={props.initialStyles}>
      <ImageContainer>
        <ImageText
          color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
          fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
        >
          {props.image.title}
        </ImageText>
        <img
          src={props.image.source}
          alt="chat.jpg"
          onClick={() => {
            props.updateModalContent(props.image.source, '');
            props.toggleModal();
          }}
        />
        <ImageText
          color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
          fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
        >
          {props.image.description}
        </ImageText>
      </ImageContainer>
    </SpeechBubble>
  );
};
