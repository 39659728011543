import React from 'react';
import { ReactSVG } from 'react-svg';

type LoadImagePropsType = {
  src: string;
  alt: string;
  wrapper?: 'div' | 'span';
};

export const LoadImage: React.FC<LoadImagePropsType> = (props) => {
  return (
    <React.Fragment>
      {props.src.slice(-3) === 'svg' ? <ReactSVG src={props.src} wrapper={props.wrapper} /> : <img src={props.src} alt={props.alt} />}
    </React.Fragment>
  );
};
