import styled from 'styled-components';

export const ImageContainer = styled.div`
  cursor: pointer;
  margin-top: 10px;
  img {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const ImageText = styled.div<{ color: string; fontSize: string }>`
  color: ${(props) => props.color};
  font-family: inherit;
  font-size: ${(props) => props.fontSize};
  margin-bottom: 5px;
`;
