import React from 'react';
import { BaseScreen, BottomMessage, CircleClose, Title, Description, MessageBackground, MessageIcon, IconMessage } from './styled';

export const OutOfServiceScreen: React.FC<{}> = () => {
  return (
    <BaseScreen>
      <BottomMessage>
        <Title>Chatbot</Title>
        <Description>out of service</Description>
        <CircleClose />
      </BottomMessage>
    </BaseScreen>
  );
};

export const OutOfServiceMessage: React.FC<{ isPreview: boolean }> = ({ isPreview }) => {
  return (
    <MessageBackground>
      <MessageIcon />
      <div>
        <IconMessage>Out of service</IconMessage>
        {isPreview && <IconMessage>Upgrade plan</IconMessage>}
      </div>
    </MessageBackground>
  );
};
