import styled from 'styled-components';

export const Container = styled.div<{ hide: boolean }>`
  position: absolute;
  bottom: 50px;
  background-color: #ccc;
  padding: 20px;
  width: 100%;
  display: ${(props) => (props.hide ? 'none' : 'block')};
`;

export const CloseIcon = styled('span')`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
  }
`;
