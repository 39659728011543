import React, { useEffect, useRef, useState } from 'react';
import { animateScroll } from 'react-scroll';
import REACTGAChatbox from 'react-ga';
import io from 'socket.io-client';
import { blahboxService, ContextType } from '../../../shared/services/chatbotService';
import { ChatboxV1Render } from '../ChatboxRenders/ChatBoxV1/ChatBoxV1Render';
import { ChatboxV2Render } from '../ChatboxRenders/ChatBoxv2/ChatBoxV2Render';
import { ChatConfigType, ExternalConfigType, MessageType, ResponseTypes, StylesType, WidgetTypes, WidgetTypesType } from './types';
import { Socket } from 'socket.io-client';

type ChatboxContentProps = {
  previewMode: boolean;
  active: boolean;
  styles: StylesType;
  openChat: boolean;
  toggleChatWindow: (open: boolean) => void;
  name: string;
  chatConfig: ChatConfigType;
  enable: boolean;
  isFreeVersion: boolean;
  placeholderText: string;
  chatId: string;
  wakeupWord: string;
  endedWord: string;
  analyticsTrackID: string;
  nameConfig: string | null;
  widgetType: WidgetTypesType;
  userParams: {
    [key: string]: any;
  };
  socketServer: string;
  externalConfig?: ExternalConfigType;
};

type SocketMessageType = {
  username: string;
  message: string;
};

export const ChatboxContent: React.FC<ChatboxContentProps> = ({
  previewMode,
  active,
  styles,
  openChat,
  toggleChatWindow,
  name,
  chatConfig,
  enable,
  isFreeVersion,
  placeholderText,
  chatId,
  wakeupWord,
  endedWord,
  analyticsTrackID,
  nameConfig,
  widgetType,
  userParams,
  socketServer,
  externalConfig,
}) => {
  const [currentContext, setCurrentContext] = useState<ContextType | undefined>(undefined);
  const [socketsActive, setSocketsActive] = useState<boolean>(false);
  const [socketId, setSocketId] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [postedMessages, setPostedMessages] = useState<Array<MessageType>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');
  const [modalContentAltText, setModalContentAltText] = useState<string | undefined>(undefined);
  const [openTopMenu, setOpenTopMenu] = useState<boolean>(false);

  let socketRef = useRef<typeof Socket | string>('');

  useEffect(() => {
    if (postedMessages.length) {
      scrollToBottom();

      //Save messages
      window.sessionStorage.setItem('blahbox-' + chatId, JSON.stringify(postedMessages));

      if (postedMessages[postedMessages.length - 1].mine) {
        const lastMessage = postedMessages[postedMessages.length - 1].text;

        if (lastMessage !== undefined) {
          sendMessageRequest(lastMessage);
        }
      }

      setIsTyping(false);
    } else if (currentContext) {
      //For clear chat
      setIsTyping(true);
      sendMessageRequest(wakeupWord);
    }
  }, [postedMessages]);

  useEffect(() => {
    if (socketsActive) {
      if (socketId === '') {
        // console.log('Socket Server: ', socketServer);
        //@ts-ignore
        socketRef.current = io.connect(socketServer, {
          "transports": ['websocket']
        });
        //@ts-ignore
        socketRef.current.on('connect', () => {
          //@ts-ignore
          setSocketId(socketRef.current.id);
        });

        let sessionConversationID = window.sessionStorage.getItem('blahbox-conversationID-' + chatId);

        //@ts-ignore
        socketRef.current.emit('customData', { conversation_id: sessionConversationID })
        window.sessionStorage.setItem('blahbox-socket-' + chatId, 'active')
      }
    }
  }, [socketsActive, socketId]);

  useEffect(() => {
    if (socketId !== '') {
      //@ts-ignore
      socketRef.current.on('new_message', (response: SocketMessageType) => {
        //render a new message only if is not ours
        if (response.username !== socketId) {
          const agentMessage = {
            response_type: ResponseTypes.TEXT,
            text: response.message,
            mine: false,
          };
          addMessage(agentMessage);
        }
      });

      //@ts-ignore
      socketRef.current.on('typing', (response: SocketMessageType) => {
        //console.log('typing desde el client');
        if (response.username !== socketId) {
          setIsTyping(true);
        }
      });

      //@ts-ignore
      socketRef.current.on('ended', (response: SocketMessageType) => {
        //console.log('socket ended');
        //@ts-ignore
        socketRef.current.disconnect(true);
        setSocketsActive(false);
        setSocketId('');
        window.sessionStorage.setItem('blahbox-socket-' + chatId, 'none')
      });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketId]);

  useEffect(() => {
    if (!socketsActive) {
      sendMessageRequest(endedWord);
    }
  }, [socketsActive]);

  useEffect(() => {
    // Send the init message the first time.
    if (openChat && !currentContext) {
      getContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openChat]);


  async function getContext() {
    if (!chatId) {
      console.log('the chatId is not correct. Could you please check it? :) ');
      return;
    }

    setIsTyping(true);

    const response = await blahboxService.getContext(wakeupWord, chatId, userParams);

    let sessionConversationID = window.sessionStorage.getItem('blahbox-conversationID-' + chatId);

    if (sessionConversationID && response.data) {
      response.data.context.conversation_id = sessionConversationID
    } else if (response && response.data) {
      window.sessionStorage.setItem('blahbox-conversationID-' + chatId, response.data.context.conversation_id);
    }

    if (!response.success || !response.data) {
      return console.log(response.error);
    }

    //Tiene en conversation id
    setCurrentContext({ ...response.data.context, userParams });

    //X-IBM-Client-Id: process.env.MIDDLEAPIKEY
    REACTGAChatbox.event(
      {
        category: previewMode ? 'New Message Preview' : 'New Message',
        action: 'Chat ' + chatId,
      },
      ['chatboxTracker']
    );

    if (window.sessionStorage.getItem('blahbox-socket-' + chatId) === 'active') {
      setSocketsActive(true)
    }

    if (window.sessionStorage.getItem('blahbox-' + chatId)) {
      loadOldMessages();
    } else {
      const messages = response.data.output.generic;
      (() => {
        for (const messageObject of messages) {
          if (messageObject.response_type !== ResponseTypes.PAUSE) {
            addMessage({ ...messageObject, mine: false });
          }
        }
      })();
    }
  }

  const loadOldMessages = () => {
    const oldMessages = window.sessionStorage.getItem('blahbox-' + chatId);
    if (oldMessages !== null) {
      setPostedMessages(JSON.parse(oldMessages));
    }
  };

  /*   const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "custom-scroll",
      duration: 500,
    });
  }; */

  const scrollToBottom = () => {
    if (document.getElementById('custom-scroll') !== null) {
      animateScroll.scrollToBottom({
        containerId: 'custom-scroll',
        duration: 500,
      });
    }
  };

  const sendMessageRequest = async (message: string) => {
    if (!currentContext) {
      return;
    }

    if (!socketsActive) {
      const response = await blahboxService.sendMessage(message, currentContext);

      if (!response.success) {
        return console.log(response.error);
      }

      REACTGAChatbox.event(
        {
          category: previewMode ? 'New Message Preview' : 'New Message',
          action: 'Chat ' + chatId,
        },
        ['chatboxTracker'],
      );

      //@ts-ignore
      const context = response.data.context.humanAgentTranfer;

      if (context) {
        setSocketsActive(true);
      }

      //@ts-ignore
      const intent = response.data.output.intents[0]
        ? //@ts-ignore
        response.data.output.intents[0].intent
        : null;

      if (Boolean(analyticsTrackID)) {
        REACTGAChatbox.event(
          {
            category: previewMode ? 'Message Preview ' + nameConfig : 'Message ' + nameConfig,
            action: intent || 'no intent',
          },
          ['userAnalytics'],
        );
      }


      //@ts-ignore
      const messages = response.data.output.generic;
      (async () => {
        let delay = 0;
        for (const messageObject of messages) {
          if (messageObject.response_type === 'pause') {
            delay = 3000; //messageObject.time;
            setIsTyping(true);
          } else {
            await addMessage({ ...messageObject, mine: false }, delay);
          }
        }
      })();


      //@ts-ignore
      setCurrentContext({ ...response.data.context, userParams });
    }
  };

  const addMessage = (message: MessageType, delay?: number) => {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          if (socketsActive && message.mine) {
            //@ts-ignore
            const userName = userParams && userParams.name ? userParams.name : 'NO_NAME';

            //@ts-ignore
            socketRef.current.emit('new_message', {
              message: message.text,
              username: userName,
              chatId: chatId,
              userParams: userParams,
              conversation_id: currentContext?.conversation_id,
            });
          }

          setPostedMessages((prevPostedMessages) => [...prevPostedMessages, message]);

          if (!message.mine) {
            setIsTyping(false);
          }
          resolve('resolved');
        },
        delay ? delay : 0,
      );
    });
  };

  const sendMessage = (message: string, hidden?: boolean) => {
    if (message === '') {
      return;
    }

    const userMessage = {
      response_type: ResponseTypes.TEXT,
      text: message,
      mine: true,
    };

    if (!hidden) {
      addMessage(userMessage);
    } else {
      setIsTyping(true);
    }
  };

  const clearChat = () => {
    window.sessionStorage.removeItem('blahbox-status-' + chatId);
    setPostedMessages([]);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const updateModalContent = (imageUrl: string, altText?: string) => {
    setModalContent(imageUrl);
    setModalContentAltText(altText);
  };

  return widgetType === WidgetTypes.ELEGANT ? (
    <ChatboxV2Render
      previewMode={previewMode}
      active={active}
      styles={styles}
      openChat={openChat}
      clearChat={clearChat}
      toggleChatWindow={toggleChatWindow}
      name={name}
      chatConfig={chatConfig}
      isTyping={isTyping}
      enable={enable}
      postedMessages={postedMessages}
      sendMessage={sendMessage}
      updateModalContent={updateModalContent}
      toggleModal={toggleModal}
      isFreeVersion={isFreeVersion}
      placeholderText={placeholderText}
      chatId={chatId}
      openModal={openModal}
      modalContent={modalContent}
      modalContentAltText={modalContentAltText}
      openTopMenu={openTopMenu}
      setOpenTopMenu={setOpenTopMenu}
      externalConfig={externalConfig}
    />
  ) : (
    <ChatboxV1Render
      previewMode={previewMode}
      active={active}
      styles={styles}
      openChat={openChat}
      clearChat={clearChat}
      toggleChatWindow={toggleChatWindow}
      name={name}
      chatConfig={chatConfig}
      isTyping={isTyping}
      enable={enable}
      postedMessages={postedMessages}
      sendMessage={sendMessage}
      updateModalContent={updateModalContent}
      toggleModal={toggleModal}
      isFreeVersion={isFreeVersion}
      placeholderText={placeholderText}
      chatId={chatId}
      openModal={openModal}
      modalContent={modalContent}
      modalContentAltText={modalContentAltText}
      externalConfig={externalConfig}
    />
  );
};
