import ReactDOM from 'react-dom';
import React from 'react';
import Chatbox from './components/Chatbox/ChatboxRoot/ChatboxContainer';

export const initChat = (element, chatId = 1, preview = false, userParams = {}, externalConfig = {}, token) => {
  const root = ReactDOM.createRoot(element);
  root.render(
    <React.StrictMode>
      <Chatbox chatId={chatId} previewMode={preview} token={token} userParams={userParams} externalConfig={externalConfig} />
    </React.StrictMode>,
  );
};

/* To init the chat please add this code
We need to link the correct css and js files here

      (function (b, c, d, ex) {
        var e = document.createElement("link");
        (e.rel = "stylesheet"),
          (e.type = "text/css"),
          (e.href = "https://chatboxlive.blahbox.net/static/css/main.css"),
          document.getElementsByTagName("head")[0].appendChild(e);
        var f = document.createElement("script");
        (f.onload = function () {
          var g;
          if (c) g = "previewInit";
          else {
            var h = document.createElement("div");
            (g = "cbinit"), (h.id = "cbinit"), document.body.append(h);
          }
          console.log(document.querySelector("#" + g)),
            chatbox.initChat(document.querySelector("#" + g), b, c, d, ex);
        }),
          //(f.src = "https://chatboxlive.blahbox.net/static/js/chat-lib.js"),
          (f.src = "./new/chat-lib.js"),
          document.getElementsByTagName("head")[0].appendChild(f);
      })(
        "eb49dcb62a1484c543b10c02d42072b3",
        0,
        {
          param1: "User Param 1",
          param2: "User Param 2",
          param3: "User Param 3",
        },
        {
          hideHeader: true,
          startOpened: true,
        }
      );

*/
