import styled from 'styled-components';
import { ReactComponent as ArrowRightSvg } from '../../../../../commons/svg/icons/right-arrow-gray.svg';

export const SliderContainer = styled.div`
  && {
    .slick-arrow div {
      display: block;
    }

    .slick-prev {
      left: 5px;
      z-index: 10;

      &:before {
        display: none;
      }
    }

    .slick-next {
      right: 5px;
      z-index: 10;
      &:before {
        display: none;
      }
    }

    /*
    .slick-next,
    .slick-prev {
      height: 80%;
      top: 0%;
      > div {
        position: relative;
        top: 50%;
      }
    }*/
  }
`;

export const SlideContainer = styled.div<{ fontSize: string }>`
  margin-bottom: 20px;
  font-size: ${(props) => props.fontSize};
  font-family: inherit;
`;

export const SlideContent = styled.div`
  width: 100%;
`;

export const ImageSection = styled.div`
  cursor: pointer;
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const TitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  text-align: left;
  width: 100%;
`;

export const Title = styled.div`
  color: #fff;
  font-family: inherit;
  font-size: inherit;
`;

export const ContentContainer = styled.div`
  padding: 10px 20px;
  text-align: left;
  min-height: 56px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  display: block;
  text-decoration: none;
  *:focus {
    outline: 0;
  }
`;

export const ContentText = styled.div`
  color: #333;
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
`;

export const CardButton = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px;
  background-color: #fff;
  cursor: pointer;
  color: #38b4e0;
`;

export const CardButtonContainer = styled.div`
  border: 0, 1px solid rgba(0, 0, 0, 0.1);
  1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const ArrowRight = styled(ArrowRightSvg)`
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.46));
  path {
    fill: #fff;
    box-shadow: 5px 5px 15px 5px #000000;
  }
`;

export const RotateContent = styled.div`
  transform: rotate(180deg);
`;
