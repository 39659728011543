import React, { useEffect, useState } from 'react';
import { LoadImage } from '../../../LoadImage/LoadImage';
import TypingGif from '../../../../commons/images/typing.gif';
import { ChatTopContainer, CloseButton, ContactPicture, Status, Controls } from './styled';
import { ChatTopStylesType } from '../../ChatboxRoot/types';

type ChatTopPropsType = {
  initialStyles: ChatTopStylesType;
  isTyping: boolean;
  avatar: string;
  name: string;
  toggleChatWindow: (open: boolean) => void;
};

export const ChatTop: React.FC<ChatTopPropsType> = (props) => {
  const [name, setName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [newStyles, setNewStyles] = useState<ChatTopStylesType>({
    backgroundColor: '',
    fontFamily: '',
    textAlign: '',
    color: '',
    fontSize: '',
    avatarBorderRadius: '',
    boxShadow: '',
    buttonColor: '',
    buttonHoverColor: '',
    buttonBackgroundColor: '',
    buttonBackgroundHoverColor: '',
    borderTopLeftRadius: '',
    borderTopRightRadius: '',
    borderBottomRightRadius: '',
    borderBottomLeftRadius: '',
  });

  useEffect(() => {
    window.chatbot.updateChatTopStyles = (userStyles: ChatTopStylesType) => {
      setNewStyles({ ...newStyles, ...userStyles });
    };
    window.chatbot.updateChatTopName = (newName: string) => {
      setName(newName);
    };

    window.chatbot.updateChatContactPicture = (imgUrl: string) => {
      setAvatar(imgUrl);
    };
  }, [newStyles]);

  const {
    fontFamily,
    backgroundColor,
    textAlign,
    color,
    fontSize,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    avatarBorderRadius,
    boxShadow,
    buttonColor,
    buttonHoverColor,
    buttonBackgroundColor,
    buttonBackgroundHoverColor,
  } = props.initialStyles;

  return (
    <ChatTopContainer
      fontFamily={newStyles.fontFamily ? newStyles.fontFamily : fontFamily}
      backgroundColor={newStyles.backgroundColor ? newStyles.backgroundColor : backgroundColor}
      textAlign={newStyles.textAlign ? newStyles.textAlign : textAlign}
      color={newStyles.color ? newStyles.color : color}
      fontSize={newStyles.fontSize ? newStyles.fontSize : fontSize}
      borderTopLeftRadius={newStyles.borderTopLeftRadius ? newStyles.borderTopLeftRadius : borderTopLeftRadius}
      borderTopRightRadius={newStyles.borderTopRightRadius ? newStyles.borderTopRightRadius : borderTopRightRadius}
      borderBottomRightRadius={newStyles.borderBottomRightRadius ? newStyles.borderBottomRightRadius : borderBottomRightRadius}
      borderBottomLeftRadius={newStyles.borderBottomLeftRadius ? newStyles.borderBottomLeftRadius : borderBottomLeftRadius}
      avatarBorderRadius={newStyles.avatarBorderRadius ? newStyles.avatarBorderRadius : avatarBorderRadius}
      boxShadow={newStyles.boxShadow ? newStyles.boxShadow : boxShadow}
    >
      <ContactPicture>
        <LoadImage src={avatar ? avatar : props.avatar} wrapper="span" alt="avatar" />
      </ContactPicture>
      <div className="contact-name">{name ? name : props.name}</div>
      {props.isTyping ? (
        <Status>
          <img src={TypingGif} alt={'typing'} />
        </Status>
      ) : (
        ''
      )}
      <Controls>
        <CloseButton
          buttonColor={newStyles.buttonColor ? newStyles.buttonColor : buttonColor}
          buttonHoverColor={newStyles.buttonHoverColor ? newStyles.buttonHoverColor : buttonHoverColor}
          buttonBackgroundColor={newStyles.buttonBackgroundColor ? newStyles.buttonBackgroundColor : buttonBackgroundColor}
          buttonBackgroundHoverColor={
            newStyles.buttonBackgroundHoverColor ? newStyles.buttonBackgroundHoverColor : buttonBackgroundHoverColor
          }
          onClick={(e) => {
            props.toggleChatWindow(false);
          }}
        >
          <svg version="1.1" id="Capa_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64">
            <g>
              <path
                fill="#FFFFFF"
                d="M28.941,31.786L0.613,60.114c-0.787,0.786-0.787,2.062,0,2.849c0.393,0.395,0.909,0.59,1.424,0.59
																		c0.516,0,1.031-0.195,1.424-0.59l28.541-28.541l28.541,28.541c0.395,0.395,0.909,0.59,1.424,0.59c0.516,0,1.031-0.195,1.424-0.59
																		c0.787-0.787,0.787-2.063,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.063-0.786-2.848,0
																		L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"
              />
            </g>
          </svg>
        </CloseButton>
      </Controls>
    </ChatTopContainer>
  );
};
