import React from 'react';
import { GlobeStylesType, ListSelectMessageType } from '../../../ChatboxRoot/types';
import { ListContainer, TitleContainer, Title, ListItem, ItemTitle, ItemDescription, ItemImage, ItemText } from './styled';

type ListRenderPropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  list: ListSelectMessageType;
  selectOption: (value: string) => void;
};

export const ListRender: React.FC<ListRenderPropsType> = (props) => {
  return (
    <ListContainer
      boxShadow={props.currentStyles.boxShadow ? props.currentStyles.boxShadow : props.initialStyles.boxShadow}
      color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
      fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
    >
      <TitleContainer
        backgroundColor={props.currentStyles.backgroundColor ? props.currentStyles.backgroundColor : props.initialStyles.backgroundColor}
      >
        <Title>{props.list.title}</Title>
      </TitleContainer>
      <div>
        {props.list.items.map((item) => (
          <ListItem key={item.image.imageUri} onClick={(e) => props.selectOption(item.info.key)}>
            {item.image.imageUri && <ItemImage src={item.image.imageUri} alt={item.image.accessibilityText} />}
            <ItemText>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemDescription>{item.description}</ItemDescription>
            </ItemText>
          </ListItem>
        ))}
      </div>
    </ListContainer>
  );
};
