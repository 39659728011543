import React from 'react';
import {
  CardContainer,
  ImageSection,
  Image,
  TitleContainer,
  Title,
  Subtitle,
  ContentContainer,
  ContentText,
  LinkContainer,
  LinkText,
  CardButton,
  CardButtonContainer,
} from './styled';
import { ReactComponent as ExternalImage } from '../../../../../commons/svg/icons/external-link.svg';
import { MessageTime } from '../styled';
import { getTwoDigitsDate } from '../../../../../commons/js/utils';
import { formatText } from '../../../../../commons/js/utils';
import { BasicCardMessageType, GlobeStylesType } from '../../../ChatboxRoot/types';

type CardRenderPropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  card: BasicCardMessageType;
  updateModalContent: (imageUrl: string, altText?: string) => void;
  toggleModal: () => void;
  onClickButton: (value: string) => void;
};

export const CardRender: React.FC<CardRenderPropsType> = (props) => {
  return (
    <>
      <CardContainer fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}>
        <ImageSection
          onClick={() => {
            if (props.card.image.modal !== false) {
              props.updateModalContent(props.card.image.imageUri, props.card.image.accessibilityText);
              props.toggleModal();
            }
          }}
        >
          <Image src={props.card.image.imageUri} alt={props.card.image.accessibilityText} />
          {props.card.title || props.card.subtitle ? (
            <TitleContainer>
              <Title>{props.card.title}</Title>
              <Subtitle>{props.card.subtitle}</Subtitle>
            </TitleContainer>
          ) : (
            ''
          )}
        </ImageSection>
        {props.card.formattedText ? (
          <ContentContainer>
            <ContentText>{formatText(props.card.formattedText)}</ContentText>
          </ContentContainer>
        ) : (
          ''
        )}
        {props.card.buttonsOptions ? (
          <CardButtonContainer>
            {props.card.buttonsOptions.map((button, index: number) => (
              <CardButton
                key={index}
                onClick={(e) => {
                  props.onClickButton(button.value);
                }}
              >
                {button.text}
              </CardButton>
            ))}
          </CardButtonContainer>
        ) : (
          ''
        )}
        {props.card.custom && (
          <ContentContainer>
            <ContentText>{formatText(props.card.custom)}</ContentText>
          </ContentContainer>
        )}
        {props.card.buttons ? (
          <LinkContainer>
            {props.card.buttons.map((button, index: number) => (
              <LinkText key={index} href={button.openUriAction.uri} target="_blank">
                <ExternalImage />
                {button.title}
              </LinkText>
            ))}
          </LinkContainer>
        ) : (
          ''
        )}
      </CardContainer>
      <MessageTime
        hideTime={props.currentStyles.hideTime !== '' ? props.currentStyles.hideTime : props.initialStyles.hideTime}
        color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
      >
        {getTwoDigitsDate(Date.now())}
      </MessageTime>
    </>
  );
};
