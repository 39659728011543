import React from 'react';
import { formatText } from '../../../../../commons/js/utils';
import { MessageText } from '../SpeechBubble/styled';
import { SpeechBubble } from '../SpeechBubble';
import { ListItem } from './styled';
import { ReactComponent as RightArrow } from '../../../../../commons/svg/icons/right-arrow.svg';
import { GlobeStylesType, OptionMessageType } from '../../../ChatboxRoot/types';

type OptionsRenderPropsType = {
  options: OptionMessageType;
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  selectOption: (value: string) => void;
};

export const OptionsRender: React.FC<OptionsRenderPropsType> = (props) => {
  return (
    <SpeechBubble currentStyles={props.currentStyles} initialStyles={props.initialStyles}>
      <MessageText>{props.options.title}</MessageText>
      <div>
        {
        props.options.options.map((element, index: number) => (
          <ListItem key={index} onClick={(e) => props.selectOption(element.value.input.text)}>
            <RightArrow />
            {formatText(element.label)}
          </ListItem>
        ))}
      </div>
    </SpeechBubble>
  );
};
