import React from "react";
import { SpeechBubble } from "../SpeechBubble";
import { MessageText } from "../SpeechBubble/styled";
import { formatText } from "../../../../../commons/js/utils";
import { GlobeStylesType } from "../../../ChatboxRoot/types";
import ReactMarkdown from "react-markdown";

type TextRenderPropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  text: string;
  format: string;
};

export const TextRender: React.FC<TextRenderPropsType> = (props) => {
  return (
    <SpeechBubble
      currentStyles={props.currentStyles}
      initialStyles={props.initialStyles}
    >
      <MessageText>
        {props.format === "markdown" ? (
          <ReactMarkdown disallowedElements={["paragraph"]} unwrapDisallowed>
            {props.text}
          </ReactMarkdown>
        ) : (
          formatText(props.text)
        )}
      </MessageText>
    </SpeechBubble>
  );
};
