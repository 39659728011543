import React, { useEffect, useState } from 'react';
import initialStyles from './chatStylesTree.json';
import WebFont from 'webfontloader';
import REACTGAChatbox from 'react-ga';
import { blahboxService } from '../../../shared/services/chatbotService';
import { ChatboxContent } from './ChatBoxContent';
import { ChatConfigType, ExternalConfigType, StylesType, WidgetTypesType } from './types';

type ChatboxPropsType = {
  chatId: string;
  previewMode: boolean;
  token: string;
  userParams: {
    [key: string]: any;
  };
  externalConfig?: ExternalConfigType;
};

export const Chatbox: React.FC<ChatboxPropsType> = (props) => {
  const [active, setActive] = useState<boolean>(false);
  const [openChat, setOpenChat] = useState<boolean>(props.externalConfig !== undefined && props.externalConfig.startOpened !== false);
  const [name, setName] = useState<string>('Your bot friend');
  const [nameConfig, setNameConfig] = useState<string | null>(null);
  const [placeholderText, setPlaceholderText] = useState<string>('Ingrese su consulta');
  const [styles, setStyles] = useState<StylesType>(initialStyles); //styles tree structure,
  const [wakeupWord, setWakeupWord] = useState<string>('hola');
  const [analyticsTrackID, setAnalyticsTrackID] = useState<string>('');
  const [isFreeVersion, setIsFreeVersion] = useState<boolean>(false);
  const [enable, setEnable] = useState<boolean>(true);
  const [widgetType, setWidgetType] = useState<WidgetTypesType>(undefined);
  const [endedWord, setEndedWord] = useState<string>('hola');
  const [socketServer, setSocketServer] = useState<string>('');

  //console.log(props.userParams);
  //console.log(props.externalConfig);

  const [chatConfig, setChatConfig] = useState<ChatConfigType>({
    // initial configuration
    chatIcon: {
      text: 'Necesitás ayuda?',
      iconImage: {
        public_id: 'chat-icon-4',
        secure_url: 'https://res.cloudinary.com/blahbox/image/upload/v1549165363/chat-icons/chat-icon-4.svg',
      },
    },
    chatTop: {
      name: 'new json',
      avatar: {
        public_id: 'logo',
        secure_url: 'https://res.cloudinary.com/blahbox/image/upload/v1547679671/logo.svg',
      },
    },
    writeSection: {
      placeholderText: 'Ingrese su consulta',
      sendButton: {
        secure_url: 'https://res.cloudinary.com/blahbox/image/upload/v1549911595/sendButton/edit-send1.svg',
      },
    },
    messagesSection: {
      contentFormat: 'html',
    },
  });

  useEffect(() => {
    blahboxService.init(props.token, props.chatId, props.previewMode);
  }, [props.token, props.chatId, props.previewMode]);

  useEffect(() => {
    REACTGAChatbox.initialize(
      //@ts-ignore
      [
        {
          trackingId: process.env.ANALYTICSTRACKID_BACK,
          gaOptions: {
            name: 'backTracker',
          },
        },
        {
          trackingId: process.env.ANALYTICSTRACKID_BOX,
          gaOptions: {
            name: 'chatboxTracker',
          },
        },
      ],
      {
        debug: process.env.NODE_ENV === 'development' ? true : false,
        alwaysSendToDefaultTracker: false,
      },
    );

    window.chatbot = {};

    window.chatbot.openChat = () => {
      setViewportTag(true);
      saveChatStatus(true);
      setOpenChat(true);
    };

    window.chatbot.closeChat = () => {
      setOpenChat(false);
    };

    /*     if (window.sessionStorage.getItem('blahbox-' + props.chatId) && loadChatStatus()) {
          toggleChatWindow(true);
        } */

    if (window.sessionStorage.getItem('blahbox-' + props.chatId) && loadChatStatus()) {
      const status = loadChatStatus();

      if (status !== null) {
        setOpenChat(JSON.parse(status));
      }
    }

    if (props.chatId) REACTGAChatbox.pageview('/chatbox/load', ['chatboxTracker']);

    if (props.previewMode) {
      localStorage.setItem('bxuser', 'tr23k4jh23khj423kj4ue');
    }

    getStylesAndSubscription();

    WebFont.load({
      google: {
        families: ['Roboto', 'Black+Han+Sans', 'Roboto:italic', 'Roboto:bold'],
      },
    });

    if (props.chatId) {
      REACTGAChatbox.event(
        {
          category: props.previewMode ? 'Load Preview' : 'Load',
          action: 'Chat ' + props.chatId,
        },
        ['chatboxTracker'],
      );
    }
  }, []);

  useEffect(() => {
    if (Boolean(analyticsTrackID)) {
      REACTGAChatbox.ga('create', analyticsTrackID, {
        name: 'userAnalytics',
      });
      REACTGAChatbox.pageview('/Blahbox_' + nameConfig + '/load', ['userAnalytics']);
    }
  }, [analyticsTrackID, nameConfig]);

  const getStylesAndSubscription = async () => {
    const response = await blahboxService.getStyles();

    if (!response.success || !response.data) {
      return console.log(response.error);
    }

    //set Initial config
    setChatConfig(response.data.config);

    //set socket server
    setSocketServer(response.data.socketServer ? response.data.socketServer.url : '');
    //setSocketServer('https://spv-socketserver.dev.blahboxint.net')
    //setSocketServer('http://localhost:3000')
    setEndedWord(response.data.socketServer ? response.data.socketServer.endedWord : '');

    //set name
    setName(response.data.config.chatTop.name);

    //set placeholderText
    setPlaceholderText(response.data.config.writeSection.placeholderText);

    //set server styles
    setStyles({
      ...styles,
      ...response.data.styles,
    });

    //set wakeupWord and analyticsID
    setWakeupWord(response.data.wakeupWord);
    setNameConfig(response.data.name);
    setAnalyticsTrackID(response.data.analyticsTrackID);
    setWidgetType(response.data.widgetType);

    //active Chat
    setActive(true);

    const subscriptionData = await blahboxService.getSubscription(response.data.subscription);

    if (!subscriptionData.success || !subscriptionData.data) {
      return console.log(response.error);
    }

    /* Check if it is enabled or out of service */
    setEnable(subscriptionData.data.Enable);

    /* Check if we are on the tiral version */
    const isFreeVersion = subscriptionData.data.ProductCode !== undefined && subscriptionData.data.ProductCode === 'freeM';
    setIsFreeVersion(isFreeVersion);
  };

  const toggleChatWindow = (open: boolean) => {
    setViewportTag(open);
    saveChatStatus(open);
    setOpenChat(open);
  };

  const setViewportTag = (isOpen: boolean) => {
    if (isOpen) {
      const viewport = document.createElement('meta');
      viewport.id = 'blahbox-viewport';
      viewport.name = 'viewport';
      viewport.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0, shrink-to-fit=no';

      document.head.appendChild(viewport);
    } else {
      const settedViewport = document.head.querySelector('#blahbox-viewport');
      if (settedViewport !== null) {
        settedViewport.remove();
      }
    }
  };

  const loadChatStatus = () => {
    return window.sessionStorage.getItem('blahbox-status-' + props.chatId);
  };

  const saveChatStatus = (status: boolean) => {
    window.sessionStorage.setItem('blahbox-status-' + props.chatId, String(status));
  };

  return (
    <ChatboxContent
      previewMode={props.previewMode}
      active={active}
      styles={styles}
      openChat={openChat}
      toggleChatWindow={toggleChatWindow}
      name={name}
      chatConfig={chatConfig}
      enable={enable}
      isFreeVersion={isFreeVersion}
      placeholderText={placeholderText}
      chatId={props.chatId}
      wakeupWord={wakeupWord}
      endedWord={endedWord}
      analyticsTrackID={analyticsTrackID}
      nameConfig={nameConfig}
      widgetType={widgetType}
      userParams={props.userParams}
      socketServer={socketServer}
      externalConfig={props.externalConfig}
    />
  );
};

export default Chatbox;
