import React, { useEffect, useRef, useState } from 'react';
import { formatText } from '../../../../commons/js/utils';
import './MessagesSection.scss';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { MessagesSectionContainer, Message, UserName } from './styled';
import { BotMessage } from './BotMessage';
import { SpeechBubble } from './SpeechBubble';
import { MessageText } from './SpeechBubble/styled';
import { GlobeStylesType, MessagesSectionStylesType, MessageType } from '../../ChatboxRoot/types';

type MessagesSectionPropsType = {
  sendMessage: (message: string, hidden?: boolean) => void;
  initialStyles: MessagesSectionStylesType;
  isFreeVersion: boolean;
  postedMessages: Array<MessageType>;
  updateModalContent: (imageUrl: string, altText?: string) => void;
  botName: string;
  toggleModal: () => void;
  format: string;
  isHeaderHidden?: boolean;
};

export const MessagesSection: React.FC<MessagesSectionPropsType> = (props) => {
  const [messagesSection, setMessagesSection] = useState<{
    backgroundColor: string;
    fontFamily: string;
  }>({
    backgroundColor: '',
    fontFamily: '',
  });

  const [userGlobe, setUserGlobe] = useState<GlobeStylesType>({
    backgroundColor: '',
    borderBottomRightRadius: '',
    borderBottomLeftRadius: '',
    borderColor: '',
    borderTopLeftRadius: '',
    borderTopRightRadius: '',
    boxShadow: '',
    color: '',
    fontSize: '',
    hideName: '',
    hideTime: '',
    paddingLeft: '',
    paddingRight: '',
    paddingTop: '',
    paddingBottom: '',
    peakPosition: '',
    textAlign: '',
    textShadow: '',
  });

  const [botGlobe, setBotGlobe] = useState<GlobeStylesType>({
    backgroundColor: '',
    borderBottomRightRadius: '',
    borderBottomLeftRadius: '',
    borderColor: '',
    borderTopLeftRadius: '',
    borderTopRightRadius: '',
    boxShadow: '',
    color: '',
    fontSize: '',
    hideName: '',
    hideTime: '',
    paddingLeft: '',
    paddingRight: '',
    paddingTop: '',
    paddingBottom: '',
    peakPosition: '',
    textAlign: '',
    textShadow: '',
  });

  const scrollBarElement = useRef(null);

  useEffect(() => {
    window.chatbot.updateMessagesSectionStyles = (newStyles: { backgroundColor: string; fontFamily: string }) => {
      setMessagesSection({ ...messagesSection, ...newStyles });
    };

    window.chatbot.updateUserGlobeStyles = (newStyles: GlobeStylesType) => {
      setUserGlobe({ ...userGlobe, ...newStyles });
    };

    window.chatbot.updateBotGlobeStyles = (newStyles: GlobeStylesType) => {
      setBotGlobe({ ...botGlobe, ...newStyles });
    };

    /* Add id to scrollbar to animate */
    //@ts-ignore
    const viewElm = scrollBarElement.current.view;
    viewElm.id = 'custom-scroll';
  }, [messagesSection, userGlobe, botGlobe]);

  const selectOption = (value: string) => {
    if (value) {
      props.sendMessage(value);
    }
  };

  const hideUserName = (currentMessage: MessageType, previewsMessage: MessageType) => {
    //Hide if it is a multimessage
    if (previewsMessage && currentMessage.mine === previewsMessage.mine) {
      return true;
    }

    //Hide name by prop
    if (currentMessage.mine) {
      if (userGlobe.hideName !== '') {
        return userGlobe.hideName;
      } else {
        return props.initialStyles.userGlobe.hideName;
      }
    } else {
      if (botGlobe.hideName !== '') {
        return botGlobe.hideName;
      } else {
        return props.initialStyles.botGlobe.hideName;
      }
    }
  };

  const containerBackgroundColor = props.initialStyles.backgroundColor;
  const containerFontFamily = props.initialStyles.fontFamily;

  return (
    <MessagesSectionContainer
      backgroundColor={messagesSection.backgroundColor ? messagesSection.backgroundColor : containerBackgroundColor}
      fontFamily={messagesSection.fontFamily ? messagesSection.fontFamily : containerFontFamily}
      isFreeVersion={props.isFreeVersion}
      isHeaderHidden={props.isHeaderHidden}
    >
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={500}
        autoHideDuration={1000}
        ref={scrollBarElement}
        className="custom-scroll"
      >
        <div className="messages">
          {props.postedMessages.map((e, index, arr) => {
            const previousItem = arr[index - 1];
            const hideName = hideUserName(e, previousItem);
            return (
              <div key={index}>
                {e.mine ? (
                  <Message mine>
                    <UserName className="message-transmitter" hideName={hideName}>
                      {'User'}
                    </UserName>
                    <SpeechBubble currentStyles={userGlobe} initialStyles={props.initialStyles.userGlobe}>
                      <MessageText>{e.text}</MessageText>
                    </SpeechBubble>
                  </Message>
                ) : (
                  <Message>
                    <UserName className="message-transmitter" hideName={hideName}>
                      {props.botName}
                    </UserName>
                    <BotMessage
                      data={e}
                      currentStyles={botGlobe}
                      initialStyles={props.initialStyles.botGlobe}
                      selectOption={selectOption}
                      updateModalContent={props.updateModalContent}
                      toggleModal={props.toggleModal}
                      format={props.format}
                    />
                  </Message>
                )}
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </MessagesSectionContainer>
  );
};
