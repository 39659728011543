import styled from "styled-components";

export const ListItem = styled.div`
  background-image: url("../../../commons/svg/icons/right-arrow.svg");
  background-repeat: no-repeat;
  background-position: 0;
  background-size: 13px;
  padding-left: 0;
  margin: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: #ef7e29;
  }
`;
