import React, { useState, useLayoutEffect } from "react";
import { ChatFrameStylesType } from "../../ChatboxRoot/types";
import { Frame } from "./styled";

type ChatFrameElegantPropsType = {
  initialStyles: ChatFrameStylesType;
  isOpen: boolean;
  isPreviewMode: boolean;
  children: React.ReactNode;
  hideHeader?: boolean;
};

export const ChatFrameElegant: React.FC<ChatFrameElegantPropsType> = (
  props
) => {
  const [width, setWidth] = useState<string>("");
  const [, setHeight] = useState<string>("");

  const setLayoutHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  //Resize for mobile keyboards. Using the css variable --vh in styled.js
  useLayoutEffect(() => {
    setLayoutHeight();

    window.addEventListener("resize", () => {
      setLayoutHeight();
    });

    return () => {
      window.addEventListener("resize", () => {
        setLayoutHeight();
      });
    };
  }, []);

  window.chatbot.updateChatFrameWidth = (newValue: string) => {
    setWidth(newValue);
  };

  window.chatbot.updateChatFrameHeight = (newValue: string) => {
    setHeight(newValue);
  };

  return (
    <Frame
      width={width ? width : props.initialStyles.width}
      //height={height ? height : props.initialStyles.height}
      height={"450px"}
      isOpen={props.isOpen}
      isPreviewMode={props.isPreviewMode}
      hideHeader={props.hideHeader}
    >
      {props.children}
    </Frame>
  );
};
