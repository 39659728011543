import styled from "styled-components";

export const ModalContainer = styled.div`
  display: ${props => (props.isOpen ? "block" : "none")};
  z-index: 9000;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
    width: 50px;
  }
`;

export const ModalContent = styled.div`
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
  position: relative;
  img {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 4px;
  cursor: pointer;

  svg {
    width: 14px;
  }
`;
