import React, { useEffect, useState } from 'react';
import './ChatIcon.scss';
import classNames from 'classnames';
import { LoadImage } from '../../../LoadImage/LoadImage';
import { OutOfServiceMessage } from '../OutOfServiceScreen';
import { ChatIconContainerStyled } from './styled';
import { ChatIconStylesType } from '../../ChatboxRoot/types';

type ChatIconPropsType = {
  previewMode: boolean;
  initialStyles: ChatIconStylesType;
  openChat: boolean;
  toggleChatWindow: (open: boolean) => void;
  enable: boolean;
  iconImage: string;
  iconText: string;
};

export const ChatIcon: React.FC<ChatIconPropsType> = (props) => {
  const [iconText, setIconText] = useState<string>('');
  const [iconImage, setIconImage] = useState<string>('');
  const [newStyles, setNewStyles] = useState<ChatIconStylesType>({
    backgroundColor: '',
    color: '',
    fontFamily: '',
    fontSize: '',
    borderTopLeftRadius: '',
    borderTopRightRadius: '',
    borderBottomRightRadius: '',
    borderBottomLeftRadius: '',
    alignIcon: '',
    alwaysVisible: '',
    iconColor1: '',
    iconColor2: '',
    iconColor3: '',
    iconColor4: '',
    marginLeft: '',
    marginRight: '',
    boxShadow: '',
    verticalAlign: '',
  });

  useEffect(() => {
    window.chatbot.updateChatIconStyles = (userStyles: ChatIconStylesType) => {
      setNewStyles({ ...newStyles, ...userStyles });
    };
    window.chatbot.updateChatIconText = (newName: string) => {
      setIconText(newName);
    };
    window.chatbot.updateChatIconImage = (newUrl: string) => {
      setIconImage(newUrl);
    };
  }, [newStyles]);

  const {
    backgroundColor,
    alignIcon,
    alwaysVisible,
    color,
    fontFamily,
    fontSize,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    iconColor1,
    iconColor2,
    iconColor3,
    iconColor4,
    marginLeft,
    marginRight,
    boxShadow,
    verticalAlign,
  } = props.initialStyles;

  return (
    <ChatIconContainerStyled
      backgroundColor={newStyles.backgroundColor ? newStyles.backgroundColor : backgroundColor}
      color={newStyles.color ? newStyles.color : color}
      fontSize={newStyles.fontSize ? newStyles.fontSize : fontSize}
      fontFamily={newStyles.fontFamily ? newStyles.fontFamily : fontFamily}
      borderTopLeftRadius={newStyles.borderTopLeftRadius ? newStyles.borderTopLeftRadius : borderTopLeftRadius}
      borderTopRightRadius={newStyles.borderTopRightRadius ? newStyles.borderTopRightRadius : borderTopRightRadius}
      borderBottomRightRadius={newStyles.borderBottomRightRadius ? newStyles.borderBottomRightRadius : borderBottomRightRadius}
      borderBottomLeftRadius={newStyles.borderBottomLeftRadius ? newStyles.borderBottomLeftRadius : borderBottomLeftRadius}
      iconColor1={newStyles.iconColor1 ? newStyles.iconColor1 : iconColor1}
      iconColor2={newStyles.iconColor2 ? newStyles.iconColor2 : iconColor2}
      iconColor3={newStyles.iconColor3 ? newStyles.iconColor3 : iconColor3}
      iconColor4={newStyles.iconColor4 ? newStyles.iconColor4 : iconColor4}
      marginLeft={newStyles.marginLeft ? newStyles.marginLeft : marginLeft}
      marginRight={newStyles.marginRight ? newStyles.marginRight : marginRight}
      boxShadow={newStyles.boxShadow ? newStyles.boxShadow : boxShadow}
      className={classNames(
        'chatbox-icon',
        {
          hide: props.openChat,
        },
        newStyles.alignIcon ? newStyles.alignIcon : alignIcon,
        newStyles.alwaysVisible ? newStyles.alwaysVisible : alwaysVisible,
        newStyles.verticalAlign ? newStyles.verticalAlign : verticalAlign,
      )}
      onClick={(e) => {
        props.toggleChatWindow(true);
      }}
    >
      {!props.enable ? <OutOfServiceMessage isPreview={props.previewMode} /> : ''}
      <div className="icon-image">
        <LoadImage src={iconImage ? iconImage : props.iconImage} wrapper="span" alt={'icon'} />
      </div>
      <span className="chatbox-icon-text">{iconText ? iconText : props.iconText}</span>
    </ChatIconContainerStyled>
  );
};
