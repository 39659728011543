import styled from "styled-components";

export const ToolboxContainer = styled.div`
  padding: 10px;
  left: 0;
  text-align: right;
`;

export const ToolButton = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #a89393;
  text-decoration: underline;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: grey;
    transition: color 0.3s;
  }
`;
