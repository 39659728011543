import ReactHtmlParser from 'html-react-parser';

export const getTwoDigitsDate = (timestamp) => {
  let h = new Date(timestamp).getHours();
  let m = new Date(timestamp).getMinutes();

  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;

  const output = h + ':' + m;
  return output;
};

export const isExternal = (text) => !text.includes(window.location.hostname);

export const urlify = (text) => {
  let splitedText = text.split(' ');

  /* eslint-disable-next-line */
  var urlRegex = /^(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  splitedText = splitedText.map((text) =>
    text.replace(urlRegex, function (url) {
      const external = isExternal(url) ? 'target=_blank' : '';
      return `<a ${external} href="${url.trim()}">${url}</a>`;
    }),
  );

  return splitedText.join(' ');
};

export const formatText = (text) => {
  let t;

  t = urlify(text);
  t = t.replace(/\\n/g, '<br />');
  t = t.replace(/\n/g, '<br />');
  t = ReactHtmlParser(t);

  return t;
};

export const isColorLight = (color) => {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5; // true if it is light, false if it is dark
};
