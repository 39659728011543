import React, { useState } from "react";
import { Root } from "./styled";

type ChatFrameContainerPropsType = {
  alignFrame: string;
  zIndex: string;
  children: React.ReactNode;
};

export const ChatFrameContainer: React.FC<ChatFrameContainerPropsType> = (
  props
) => {
  const [alignFrame, setAlignFrame] = useState<string>("");

  window.chatbot.updateChatFrameAlign = (newValue: string) => {
    setAlignFrame(newValue);
  };

  return (
    <Root
      alignFrame={alignFrame ? alignFrame : props.alignFrame}
      zIndex={props.zIndex}
    >
      {props.children}
    </Root>
  );
};
