import React from 'react';
import { Globe } from './styled';
import classNames from 'classnames';
import { MessageTime } from '../styled';
import { getTwoDigitsDate } from '../../../../../commons/js/utils';
import { GlobeStylesType } from '../../../ChatboxRoot/types';

type SpeechBubblePropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  children: React.ReactNode;
};

export const SpeechBubble: React.FC<SpeechBubblePropsType> = (props) => {
  return (
    <Globe
      backgroundColor={props.currentStyles.backgroundColor ? props.currentStyles.backgroundColor : props.initialStyles.backgroundColor}
      color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
      fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
      boxShadow={props.currentStyles.boxShadow ? props.currentStyles.boxShadow : props.initialStyles.boxShadow}
      textAlign={props.currentStyles.textAlign ? props.currentStyles.textAlign : props.initialStyles.textAlign}
      borderTopLeftRadius={
        props.currentStyles.borderTopLeftRadius ? props.currentStyles.borderTopLeftRadius : props.initialStyles.borderTopLeftRadius
      }
      borderTopRightRadius={
        props.currentStyles.borderTopRightRadius ? props.currentStyles.borderTopRightRadius : props.initialStyles.borderTopRightRadius
      }
      borderBottomRightRadius={
        props.currentStyles.borderBottomRightRadius
          ? props.currentStyles.borderBottomRightRadius
          : props.initialStyles.borderBottomRightRadius
      }
      borderBottomLeftRadius={
        props.currentStyles.borderBottomLeftRadius ? props.currentStyles.borderBottomLeftRadius : props.initialStyles.borderBottomLeftRadius
      }
      paddingTop={props.currentStyles.paddingTop ? props.currentStyles.paddingTop : props.initialStyles.paddingTop}
      paddingRight={props.currentStyles.paddingRight ? props.currentStyles.paddingRight : props.initialStyles.paddingRight}
      paddingBottom={props.currentStyles.paddingBottom ? props.currentStyles.paddingBottom : props.initialStyles.paddingBottom}
      paddingLeft={props.currentStyles.paddingLeft ? props.currentStyles.paddingLeft : props.initialStyles.paddingLeft}
      className={classNames(
        'message-box',
        props.currentStyles.peakPosition ? props.currentStyles.peakPosition : props.initialStyles.peakPosition,
      )}
    >
      {props.children}
      {/* El time va siempre al final. */}
      <MessageTime
        hideTime={props.currentStyles.hideTime !== '' ? props.currentStyles.hideTime : props.initialStyles.hideTime}
        color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
      >
        {getTwoDigitsDate(Date.now())}
      </MessageTime>
    </Globe>
  );
};
