import styled from 'styled-components';
import { Globe } from '../SpeechBubble/styled';

export const ChipsContainer = styled.div``;
export const List = styled.div`
  text-align: left;
  padding-top: 10px;
`;

export const Chip = styled(Globe)<{
  backgroundColor: string;
  fontSize: string;
  boxShadow: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomRightRadius: string;
  borderBottomLeftRadius: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  marginTop: string;
  hoverOpacity: string;
  transition: string;
}>`
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  transition: ${(props) => props.transition};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  &:hover {
    opacity: ${(props) => props.hoverOpacity};
  }
`;
