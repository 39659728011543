import styled, { css } from "styled-components";

export const Frame = styled.div<{
  width: string;
  height: string;
  isPreviewMode: boolean;
  isOpen: boolean;
  hideHeader?: boolean;
}>`
  width: 100%;
  transition: height 0.2s linear;
  height: 0;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  position: relative;

  @media (min-width: 768px) {
    width: ${(props) => (props.width ? props.width : "320px")};
  }

  ${(props) =>
    props.isOpen &&
    css`
      padding-top: ${props.hideHeader ? "0" : "10px"};
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: ${props.isPreviewMode
        ? "calc(var(--vh, 1vh) * 80)"
        : "calc(var(--vh, 1vh) * 100)"};

      @media (min-width: 768px) {
        height: ${props.height ? props.height : "455px"};
      }
    `}
`;
