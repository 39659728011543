import React from 'react';
import { MessageContainer, MessageTime } from '../styled';
import { getTwoDigitsDate } from '../../../../../commons/js/utils';
import { OptionsRender } from '../OptionsRender';
import { ListRender } from '../ListRender';
import { CardRender } from '../CardRender';
import { CarouselRender } from '../CarouselRender';
import { ChipsRender } from '../ChipsRender';
import { LinkRender } from '../LinkRender';
import { TextRender } from '../TextRender';
import { ImageRender } from '../ImageRender';
import { CardSearchSkillRender } from '../SearchSkillRender';
import { GlobeStylesType, MessageType } from '../../../ChatboxRoot/types';
import { AnswerContainer } from './styled';

type BotMessagePropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  selectOption: (value: string) => void;
  updateModalContent: (imageUrl: string, altText?: string) => void;
  toggleModal: () => void;
  data: MessageType; // Different kind of messages
  format: string;
};

export const BotMessage: React.FC<BotMessagePropsType> = (props) => {
  const renderComponents = {
    text: props.data.text && (
      <TextRender currentStyles={props.currentStyles} initialStyles={props.initialStyles} text={props.data.text} format={props.format} />
    ),
    image: props.data.image && (
      <ImageRender
        image={props.data.image}
        currentStyles={props.currentStyles}
        initialStyles={props.initialStyles}
        updateModalContent={props.updateModalContent}
        toggleModal={props.toggleModal}
      />
    ),
    option: props.data.option && (
      <OptionsRender
        options={props.data.option}
        selectOption={props.selectOption}
        currentStyles={props.currentStyles}
        initialStyles={props.initialStyles}
      />
    ),
    linkOutSuggestion: props.data.linkOutSuggestion && (
      <LinkRender linkInfo={props.data.linkOutSuggestion} currentStyles={props.currentStyles} initialStyles={props.initialStyles} />
    ),
    listSelect: props.data.listSelect && (
      <MessageContainer>
        <ListRender
          list={props.data.listSelect}
          selectOption={props.selectOption}
          currentStyles={props.currentStyles}
          initialStyles={props.initialStyles}
        />
        <MessageTime
          hideTime={props.currentStyles.hideTime !== '' ? props.currentStyles.hideTime : props.initialStyles.hideTime}
          color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
        >
          {getTwoDigitsDate(Date.now())}
        </MessageTime>
      </MessageContainer>
    ),
    basicCard: props.data.basicCard && (
      <CardRender
        currentStyles={props.currentStyles}
        initialStyles={props.initialStyles}
        card={props.data.basicCard}
        updateModalContent={props.updateModalContent}
        toggleModal={props.toggleModal}
        onClickButton={props.selectOption}
      />
    ),
    carouselSelect: props.data.carouselSelect && (
      <CarouselRender
        currentStyles={props.currentStyles}
        initialStyles={props.initialStyles}
        slider={props.data.carouselSelect}
        onClick={props.selectOption}
        updateModalContent={props.updateModalContent}
        toggleModal={props.toggleModal}
      />
    ),
    suggestions: props.data.suggestions && (
      <ChipsRender
        currentStyles={props.currentStyles}
        initialStyles={props.initialStyles}
        suggestions={props.data.suggestions}
        onClick={props.selectOption}
      />
    ),
    search: props.data.header && props.data.results && (
      <CardSearchSkillRender
        currentStyles={props.currentStyles}
        initialStyles={props.initialStyles}
        header={props.data.header}
        results={props.data.results}
        selectOption={props.selectOption}
      />
    ),
    pause: <></>,
  };

  return <AnswerContainer>{renderComponents[props.data.response_type]}</AnswerContainer>;
};
