import React from 'react';
import { SpeechBubble } from '../SpeechBubble';
import { ChipsContainer, List, Chip } from './styled';
import { formatText } from '../../../../../commons/js/utils';
import { MessageText } from '../SpeechBubble/styled';
import { ChipsStylesType, GlobeStylesType, SuggestionsMessageType } from '../../../ChatboxRoot/types';

type ChipsRenderPropsType = {
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  suggestions: SuggestionsMessageType;
  onClick: (value: string) => void;
};

export const ChipsRender: React.FC<ChipsRenderPropsType> = (props) => {
  return (
    <ChipsContainer>
      {props.suggestions.title ? (
        <SpeechBubble currentStyles={props.currentStyles} initialStyles={props.initialStyles}>
          <MessageText>{formatText(props.suggestions.title)}</MessageText>
        </SpeechBubble>
      ) : (
        ''
      )}
      {props.suggestions.Options && (
        <List>
          {props.suggestions.Options.map((chip) => (
            <Chip
              className="remove-peak"
              backgroundColor={
                props.currentStyles.backgroundColor
                  ? props.currentStyles.backgroundColor
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.backgroundColor
                  : props.initialStyles.backgroundColor
              }
              color={
                props.currentStyles.color
                  ? props.currentStyles.color
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.color
                  : props.initialStyles.color
              }
              fontSize={
                props.currentStyles.fontSize
                  ? props.currentStyles.fontSize
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.fontSize
                  : props.initialStyles.fontSize
              }
              boxShadow={
                props.currentStyles.boxShadow
                  ? props.currentStyles.boxShadow
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.boxShadow
                  : props.initialStyles.boxShadow
              }
              textAlign={
                props.currentStyles.textAlign
                  ? props.currentStyles.textAlign
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.textAlign
                  : props.initialStyles.textAlign
              }
              borderTopLeftRadius={
                props.currentStyles.borderTopLeftRadius
                  ? props.currentStyles.borderTopLeftRadius
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.borderTopLeftRadius
                  : props.initialStyles.borderTopLeftRadius
              }
              borderTopRightRadius={
                props.currentStyles.borderTopRightRadius
                  ? props.currentStyles.borderTopRightRadius
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.borderTopRightRadius
                  : props.initialStyles.borderTopRightRadius
              }
              borderBottomRightRadius={
                props.currentStyles.borderBottomRightRadius
                  ? props.currentStyles.borderBottomRightRadius
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.borderBottomRightRadius
                  : props.initialStyles.borderBottomRightRadius
              }
              borderBottomLeftRadius={
                props.currentStyles.borderBottomLeftRadius
                  ? props.currentStyles.borderBottomLeftRadius
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.borderBottomLeftRadius
                  : props.initialStyles.borderBottomLeftRadius
              }
              paddingTop={
                props.currentStyles.paddingTop
                  ? props.currentStyles.paddingTop
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.paddingTop
                  : props.initialStyles.paddingTop
              }
              paddingRight={
                props.currentStyles.paddingRight
                  ? props.currentStyles.paddingRight
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.paddingRight
                  : props.initialStyles.paddingRight
              }
              paddingBottom={
                props.currentStyles.paddingBottom
                  ? props.currentStyles.paddingBottom
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.paddingBottom
                  : props.initialStyles.paddingBottom
              }
              paddingLeft={
                props.currentStyles.paddingLeft
                  ? props.currentStyles.paddingLeft
                  : props.initialStyles.chips
                  ? props.initialStyles.chips.paddingLeft
                  : props.initialStyles.paddingLeft
              }
              marginTop={props.initialStyles.chips ? props.initialStyles.chips.marginTop : '0'}
              marginRight={props.initialStyles.chips ? props.initialStyles.chips.marginRight : '4px'}
              marginBottom={props.initialStyles.chips ? props.initialStyles.chips.marginBottom : '4px'}
              marginLeft={props.initialStyles.chips ? props.initialStyles.chips.marginLeft : '0'}
              hoverOpacity={props.initialStyles.chips ? props.initialStyles.chips.hoverOpacity : '0.8'}
              transition={props.initialStyles.chips ? props.initialStyles.chips.transition : 'all 0.2s ease 0s'}
              key={chip}
              onClick={() => props.onClick(chip)}
            >
              {formatText(chip)}
            </Chip>
          ))}
        </List>
      )}
    </ChipsContainer>
  );
};
