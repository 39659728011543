import styled from 'styled-components';

export const Globe = styled.div<{
  backgroundColor: string;
  fontSize: string;
  boxShadow: string;
  textAlign: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomRightRadius: string;
  borderBottomLeftRadius: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
}>`
  border-radius: 10px;
  padding-top: ${(props) => props.paddingTop};
  padding-right: ${(props) => props.paddingRight};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-left: ${(props) => props.paddingLeft};
  display: inline-block;
  position: relative;
  border-color: #fff;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-family: inherit;
  box-shadow: ${(props) => props.boxShadow};
  text-align: ${(props) => props.textAlign};
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};

  b,
  strong {
    font-weight: bold;
  }

  img {
    max-width: 100%;
  }

  &::after {
    border-color: ${(props) => props.backgroundColor} ${(props) => props.backgroundColor} transparent transparent;
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border: 12px solid;
  }

  &.top-left {
    &::after {
      border-color: transparent transparent ${(props) => props.backgroundColor} ${(props) => props.backgroundColor};
      left: 5px;
      top: -10px;
    }
  }

  &.top-center {
    &::after {
      border-color: transparent transparent ${(props) => props.backgroundColor} transparent;
      left: 0;
      top: -19px;
      right: 0;
      margin: 0 auto;
    }
  }
  &.top-right {
    &::after {
      border-color: transparent ${(props) => props.backgroundColor} transparent transparent;
      right: 5px;
      top: -10px;
    }
  }

  &.left-top {
    &::after {
      border-color: ${(props) => props.backgroundColor} transparent transparent transparent;
      left: -12px;
      top: 12px;
    }
  }

  &.left-center {
    &::after {
      border-color: ${(props) => props.backgroundColor} transparent transparent transparent;
      left: -12px;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
    }
  }
  &.right-top {
    &::after {
      border-color: ${(props) => props.backgroundColor} transparent transparent transparent;
      right: -12px;
      top: 12px;
    }
  }
  &.right-center {
    &::after {
      border-color: ${(props) => props.backgroundColor} transparent transparent transparent;
      right: -12px;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
    }
  }

  &.remove-peak {
    &:after {
      display: none;
    }
  }
`;

export const UserName = styled.div<{ hideName: boolean }>`
  display: ${(props) => (props.hideName ? 'none' : 'block')};
`;

export const MessageText = styled.div`
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  margin: 12px 0;
  word-break: break-word;
  a {
    color: inherit;
    text-decoration: underline;
  }
`;
