import React from 'react';
import { MessageContainer, ChatLink, MessageTime } from '../styled';
import { getTwoDigitsDate } from '../../../../../commons/js/utils';
import { GlobeStylesType, LinkOutSuggestionMessageType } from '../../../ChatboxRoot/types';

type LinkRenderPropsType = {
  linkInfo: LinkOutSuggestionMessageType;
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
};

export const LinkRender: React.FC<LinkRenderPropsType> = (props) => {
  return (
    <MessageContainer>
      <ChatLink
        href={props.linkInfo.uri}
        backgroundColor={
          props.currentStyles.backgroundColor
            ? props.currentStyles.backgroundColor
            : props.initialStyles.linkOutSuggestion
            ? props.initialStyles.linkOutSuggestion.backgroundColor
            : props.initialStyles.backgroundColor
        }
        color={
          props.currentStyles.color
            ? props.currentStyles.color
            : props.initialStyles.linkOutSuggestion
            ? props.initialStyles.linkOutSuggestion.color
            : props.initialStyles.color
        }
        fontSize={
          props.currentStyles.fontSize
            ? props.currentStyles.fontSize
            : props.initialStyles.linkOutSuggestion
            ? props.initialStyles.linkOutSuggestion.fontSize
            : props.initialStyles.fontSize
        }
        boxShadow={
          props.currentStyles.boxShadow
            ? props.currentStyles.boxShadow
            : props.initialStyles.linkOutSuggestion
            ? props.initialStyles.linkOutSuggestion.boxShadow
            : props.initialStyles.boxShadow
        }
        borderColor={
          props.currentStyles.borderColor
            ? props.currentStyles.borderColor
            : props.initialStyles.linkOutSuggestion
            ? props.initialStyles.linkOutSuggestion.borderColor
            : props.initialStyles.borderColor
        }
        textShadow={
          props.currentStyles.textShadow
            ? props.currentStyles.textShadow
            : props.initialStyles.linkOutSuggestion
            ? props.initialStyles.linkOutSuggestion.textShadow
            : props.initialStyles.textShadow
        }
        border={props.currentStyles.linkOutSuggestion ? props.currentStyles.linkOutSuggestion.border : '1px solid'}
        hoverOpacity={props.currentStyles.linkOutSuggestion ? props.currentStyles.linkOutSuggestion.hoverOpacity : '0.8'}
        target={props.currentStyles.linkOutSuggestion ? props.currentStyles.linkOutSuggestion.target : '_blank'}
      >
        {props.linkInfo.destinationName}
      </ChatLink>
      <MessageTime
        hideTime={props.currentStyles.hideTime !== '' ? props.currentStyles.hideTime : props.initialStyles.hideTime}
        color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
      >
        {getTwoDigitsDate(Date.now())}
      </MessageTime>
    </MessageContainer>
  );
};
