import styled from 'styled-components';
import { SendButtonStylesType } from '../../../ChatboxRoot/types';

export const SendButtonContainer = styled.div<SendButtonStylesType>`
  width: 30px;
  transition: all 0.2s ease 0s;
  border-radius: 50%;
  border-style: solid;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 10px;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
  border-width: ${(props) => props.borderWidth};

  svg {
    width: 28px;
    height: 28px;
    path {
      fill: ${(props) => props.iconColor};
    }
  }

  &:hover {
    width: 50px;
    border-radius: 20px;
    outline: none;
    background-color: ${(props) => props.hoverBackgroundColor};
    border-color: ${(props) => props.hoverBorderColor};
    svg {
      path {
        fill: ${(props) => props.hoverIconColor};
      }
    }
  }
`;
