import React, { useEffect, useState } from 'react';
import { LoadImage } from '../../../../LoadImage/LoadImage';
import { SendButtonStylesType } from '../../../ChatboxRoot/types';
import { SendButtonContainer } from './styled';

type SendButtonPropsType = {
  initialStyles: SendButtonStylesType;
  onClickHandler: () => void;
  sendIconImage: string;
};

export const SendButton: React.FC<SendButtonPropsType> = (props) => {
  const [sendIconImage, setSendIconImage] = useState<string>('');
  const [newStyles, setNewStyles] = useState<SendButtonStylesType>({
    backgroundColor: '',
    borderColor: '',
    borderWidth: '',
    hoverBorderColor: '',
    hoverBackgroundColor: '',
    iconColor: '',
    hoverIconColor: '',
  });

  useEffect(() => {
    window.chatbot.updateSendButtonStyles = (userStyles: SendButtonStylesType) => {
      setNewStyles({ ...newStyles, ...userStyles });
    };
    window.chatbot.updateSendIconImage = (newUrl: string) => {
      setSendIconImage(newUrl);
    };
  }, [newStyles]);

  const { backgroundColor, borderColor, borderWidth, hoverBorderColor, hoverBackgroundColor, iconColor, hoverIconColor } =
    props.initialStyles;

  return (
    <SendButtonContainer
      backgroundColor={newStyles.backgroundColor ? newStyles.backgroundColor : backgroundColor}
      borderColor={newStyles.borderColor ? newStyles.borderColor : borderColor}
      borderWidth={newStyles.borderWidth ? newStyles.borderWidth : borderWidth}
      hoverBackgroundColor={newStyles.hoverBackgroundColor ? newStyles.hoverBackgroundColor : hoverBackgroundColor}
      hoverBorderColor={newStyles.hoverBorderColor ? newStyles.hoverBorderColor : hoverBorderColor}
      iconColor={newStyles.iconColor ? newStyles.iconColor : iconColor}
      hoverIconColor={newStyles.hoverIconColor ? newStyles.hoverIconColor : hoverIconColor}
      className="send-button"
      onClick={props.onClickHandler}
    >
      <LoadImage src={sendIconImage ? sendIconImage : props.sendIconImage} wrapper="span" alt="icon-image" />
    </SendButtonContainer>
  );
};
