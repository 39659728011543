import styled from 'styled-components';

export const Tag = styled.div`
  display: inline-block;
  background-color: #403d65;
  color: #fff;
  margin: 0 5px 10px 5px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
`;
