import React from 'react';
import Slider from 'react-slick';
import { MessageTime } from '../styled';
import { CarouselContainer } from '../../../../Carousel/styled';
import {
  SliderContainer,
  SlideContainer,
  SlideContent,
  ImageSection,
  Image,
  TitleContainer,
  Title,
  ContentContainer,
  ContentText,
  CardButton,
  CardButtonContainer,
  RotateContent,
  ArrowRight,
} from './styled';
import { formatText, getTwoDigitsDate } from '../../../../../commons/js/utils';
import { CarrouselMessageType, GlobeStylesType } from '../../../ChatboxRoot/types';

type CarouselRenderPropsType = {
  slider: CarrouselMessageType;
  currentStyles: GlobeStylesType;
  initialStyles: GlobeStylesType;
  updateModalContent: (imageUrl: string, altText?: string) => void;
  toggleModal: () => void;
  onClick: (value: string) => void;
};

export const CarouselRender: React.FC<CarouselRenderPropsType> = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <div>
        <div>
          <RotateContent>
            <ArrowRight />
          </RotateContent>
        </div>
      </div>
    ),
    nextArrow: (
      <div>
        <div>
          <ArrowRight />
        </div>
      </div>
    ),
  };

  return (
    <CarouselContainer>
      <SliderContainer>
        <Slider {...settings}>
          {props.slider.items.map((slide) => (
            <SlideContainer
              key={slide.info.key}
              fontSize={props.currentStyles.fontSize ? props.currentStyles.fontSize : props.initialStyles.fontSize}
            >
              <SlideContent>
                <ImageSection
                  onClick={() => {
                    if (slide.image.modal !== false) {
                      props.updateModalContent(slide.image.imageUri, slide.image.accessibilityText);
                      props.toggleModal();
                    }
                  }}
                >
                  <Image src={slide.image.imageUri} alt={slide.image.accessibilityText} />
                  {slide.title && (
                    <TitleContainer>
                      <Title>{slide.title}</Title>
                    </TitleContainer>
                  )}
                </ImageSection>
                {slide.description && (
                  <>
                    {slide.info.uri ? (
                      <ContentContainer as={'a'} href={slide.info.uri}>
                        <ContentText>{formatText(slide.description)}</ContentText>
                      </ContentContainer>
                    ) : (
                      <ContentContainer onClick={(e) => props.onClick(slide.info.key)}>
                        <ContentText>{formatText(slide.description)}</ContentText>
                      </ContentContainer>
                    )}
                  </>
                )}
                {slide.custom && (
                  <ContentContainer>
                    <ContentText>{formatText(slide.custom)}</ContentText>
                  </ContentContainer>
                )}
                {slide.buttonsOptions && (
                  <CardButtonContainer>
                    {slide.buttonsOptions.map((button, index) => (
                      <CardButton key={index} onClick={(e) => props.onClick(button.value)}>
                        {button.text}
                      </CardButton>
                    ))}
                  </CardButtonContainer>
                )}
              </SlideContent>
            </SlideContainer>
          ))}
        </Slider>
        <MessageTime
          hideTime={props.currentStyles.hideTime !== '' ? props.currentStyles.hideTime : props.initialStyles.hideTime}
          color={props.currentStyles.color ? props.currentStyles.color : props.initialStyles.color}
        >
          {getTwoDigitsDate(Date.now())}
        </MessageTime>
      </SliderContainer>
    </CarouselContainer>
  );
};
