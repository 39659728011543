import styled from 'styled-components';
import { BaseWriteSectionStylesType } from './WriteSection';

export const WriteSectionSectionContainer = styled.div<BaseWriteSectionStylesType>`
  background-color: ${(props) => props.backgroundColor};
  font-family: ${(props) => props.fontFamily};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  box-shadow: ${(props) => props.boxShadow};
  position: relative;
  text-align: left;

  textarea {
    margin-right: 10px;
    border: 0;
    margin: 14px 0px 0px 25px;
    padding: 0;
    font-size: inherit;
    text-align: left;
    width: 70%;
    outline: none;
    background-color: transparent;
    font-family: inherit !important;
    color: inherit;
    overflow: hidden;
    line-height: 1.2;
    resize: none;
    height: 28px;
    word-break: break-word;
    display: inline-block;

    &:focus {
      border: none;
      box-shadow: none;
      outline: 0;
    }

    &::placeholder {
      color: ${(props) => props.placeholderColor};
    }
  }
`;

export const TextField = styled.textarea`
  margin-right: 10px;
  border: 0;
  margin: 14px 0px 0px 25px;
  padding: 0;
  font-size: inherit;
  text-align: left;
  width: 70%;
  outline: none;
  background-color: transparent;
  font-family: inherit !important;
  color: inherit;
  overflow: hidden;
  line-height: 1.2;
  resize: none;
  height: 28px;
  min-height: 28px;
  word-break: break-word;
  display: inline-block;

  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
    font-size: inherit;
  }
`;

export const TrialMessage = styled.div`
  text-align: center;
`;
