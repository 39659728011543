import styled from 'styled-components';
import { ChatIconStylesType } from '../../ChatboxRoot/types';

type ChatIconStylesPropsType = Omit<ChatIconStylesType, 'alwaysVisible' | 'alignIcon' | 'verticalAlign'>;

export const ChatIconContainerStyled = styled.div<ChatIconStylesPropsType>`
  && {
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    box-shadow: ${(props) => props.boxShadow};

    svg {
      g {
        &:nth-child(1) * {
          fill: ${(props) => props.iconColor1} !important;
        }
        &:nth-child(2) * {
          fill: ${(props) => props.iconColor2} !important;
        }
        &:nth-child(3) * {
          fill: ${(props) => props.iconColor3} !important;
        }
        &:nth-child(4) * {
          fill: ${(props) => props.iconColor4} !important;
        }
      }
    }
  }
`;
