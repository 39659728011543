import styled from 'styled-components';
import { ChatTopStylesType } from '../../ChatboxRoot/types';

type ChatTopContainerStylesType = Omit<
  ChatTopStylesType,
  'buttonColor' | 'buttonHoverColor' | 'buttonBackgroundColor' | 'buttonBackgroundHoverColor' | 'avatarBorderRadius'
>;

export const ChatTopContainer = styled.div<ChatTopContainerStylesType>`
  padding: 14px 65px 0 90px;
  position: relative;
  z-index: 10;
  min-height: 47px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  font-family: ${(props) => props.fontFamily};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
  box-shadow: ${(props) => props.boxShadow};
`;

export const CloseButton = styled.div<{
  buttonBackgroundColor: string;
  buttonColor: string;
  buttonBackgroundHoverColor: string;
  buttonHoverColor: string;
}>`
  border-radius: 30px;
  -webkit-appearance: none;
  border: 0;
  transition: all 0.2s ease 0s;
  width: 34px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  text-align: center;
  background-color: ${(props) => props.buttonBackgroundColor};

  svg {
    width: 15px;
    height: 15px;
  }

  path {
    transition: all 0.2s ease 0s;
    fill: ${(props) => props.buttonColor};
  }

  &:hover {
    width: 50px;
    border-radius: 20px;
    background-color: ${(props) => props.buttonBackgroundHoverColor};
    box-sizing: border-box;
    path {
      fill: ${(props) => props.buttonHoverColor};
    }
  }
`;

export const Controls = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
`;

export const Logo = styled.div`
  max-width: 90%;
  max-height: 100%;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const TopMenuStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  svg {
    margin-top: 15px;
    margin-left: 15px;
    &:hover {
      cusor: pointer;
    }
  }
`;

export const TopMenuContentStyled = styled.div`
  position: absoluter;
  top: 40px;
  left: 0;
  ul {
    border: 1px solid #000;
  }
`;

export const MenuItemStyled = styled.li`
  display: block;
  padding: 15px;
  &:hover {
    cursor: pointer;
    color: #blue;
  }
`;
