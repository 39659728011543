import classNames from 'classnames';
import React from 'react';
import Delayed from '../../../Delayed/Delayed';
import { Modal } from '../../../Modal';
import { ChatFrameContainer } from '../../ChatboxModules/ChatFrameContainer';
import { ChatFrameElegant } from '../../ChatboxModules/ChatFrameElegant';
import { ChatIcon } from '../../ChatboxModules/ChatIcon/ChatIcon';
import { ChatTopElegant } from '../../ChatboxModules/ChatTopElegant/ChatTopElegant';
import { MessagesSection } from '../../ChatboxModules/MessagesSection/MessagesSection';
import { OutOfServiceScreen } from '../../ChatboxModules/OutOfServiceScreen';
import { Toolbox } from '../../ChatboxModules/Toolbox/Toolbox';
import { WriteSection } from '../../ChatboxModules/WriteSection/WriteSection';
import { GlobalStyle } from '../../ChatboxRoot/styled';
import { ChatConfigType, ExternalConfigType, MessageType, StylesType } from '../../ChatboxRoot/types';

type ChatboxV2RenderPropsType = {
  previewMode: boolean;
  active: boolean;
  styles: StylesType;
  openChat: boolean;
  clearChat: () => void;
  toggleChatWindow: (open: boolean) => void;
  name: string;
  chatConfig: ChatConfigType;
  isTyping: boolean;
  enable: boolean;
  postedMessages: Array<MessageType>;
  sendMessage: (message: string, hidden?: boolean) => void;
  updateModalContent: (imageUrl: string, altText?: string) => void;
  toggleModal: () => void;
  isFreeVersion: boolean;
  placeholderText: string;
  chatId: string;
  openModal: boolean;
  modalContent: string;
  modalContentAltText?: string;
  openTopMenu: boolean;
  setOpenTopMenu: (open: boolean) => void;
  externalConfig?: ExternalConfigType;
};

export const ChatboxV2Render: React.FC<ChatboxV2RenderPropsType> = ({
  previewMode,
  active,
  styles,
  openChat,
  clearChat,
  toggleChatWindow,
  name,
  chatConfig,
  isTyping,
  enable,
  postedMessages,
  sendMessage,
  updateModalContent,
  toggleModal,
  isFreeVersion,
  placeholderText,
  chatId,
  openModal,
  modalContent,
  modalContentAltText,
  openTopMenu,
  setOpenTopMenu,
  externalConfig,
}) => {
//  console.log('-----------------------');
//  console.log(externalConfig);

  return (
    <Delayed waitBeforeShow={previewMode ? 0 : 3000}>
      <div id="chatbox" className={classNames(!active ? 'hidden' : '')}>
        <ChatFrameContainer alignFrame={styles.chatFrame.alignFrame} zIndex={styles.chatFrame.zIndex}>
          {previewMode && openChat ? <Toolbox onClear={clearChat} /> : ''}
          <ChatFrameElegant
            initialStyles={styles.chatFrame}
            isOpen={openChat}
            isPreviewMode={previewMode}
            hideHeader={externalConfig && externalConfig.hideHeader}
          >
            {externalConfig && !externalConfig.hideHeader && (
              <ChatTopElegant
                initialStyles={styles.chatTop}
                toggleChatWindow={toggleChatWindow}
                logo={chatConfig.chatTop.avatar.secure_url}
                topMenu={chatConfig.chatTop.menu}
                clearChat={clearChat}
                openTopMenu={openTopMenu}
                setOpenTopMenu={setOpenTopMenu}
              />
            )}
            {!enable ? (
              <OutOfServiceScreen />
            ) : (
              <>
                <MessagesSection
                  initialStyles={styles.messagesSection}
                  postedMessages={postedMessages}
                  botName={name}
                  sendMessage={sendMessage}
                  updateModalContent={updateModalContent}
                  toggleModal={toggleModal}
                  isFreeVersion={isFreeVersion}
                  format={chatConfig.messagesSection.contentFormat}
                  isHeaderHidden={externalConfig && externalConfig.hideHeader}
                />
                <WriteSection
                  initialStyles={styles.writeSection}
                  sendMessage={sendMessage}
                  placeholderText={placeholderText}
                  sendIconImage={chatConfig.writeSection.sendButton.secure_url}
                  isFreeVersion={isFreeVersion}
                  chatId={chatId}
                />
              </>
            )}
          </ChatFrameElegant>
        </ChatFrameContainer>
        {active && (
          <ChatIcon
            initialStyles={styles.chatIcon}
            iconImage={chatConfig.chatIcon.iconImage.secure_url}
            iconText={chatConfig.chatIcon.text}
            openChat={openChat}
            toggleChatWindow={toggleChatWindow}
            enable={enable}
            previewMode={previewMode}
          />
        )}
      </div>
      <Modal isOpen={openModal} toggleModal={toggleModal}>
        <img alt={modalContentAltText ? modalContentAltText : 'modal image'} src={modalContent} />
      </Modal>
      <GlobalStyle />
    </Delayed>
  );
};
