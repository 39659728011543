import styled from 'styled-components';

export const MessagesSectionContainer = styled.div<{
  isFreeVersion: boolean;
  backgroundColor: string;
  fontFamily: string;
  isHeaderHidden?: boolean;
}>`
  height: ${(props) => {
    if (!props.isHeaderHidden) {
      if (props.isFreeVersion) {
        return 'calc(100% - 104px)';
      } else {
        return 'calc(100% - 91px)';
      }
    } else {
      if (props.isFreeVersion) {
        return 'calc(100% - 56px)';
      } else {
        return 'calc(100% - 43px)';
      }
    }
  }};
  overflow: auto;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  font-family: ${(props) => props.fontFamily};
`;

export const Message = styled.div<{ mine?: boolean }>`
  text-align: left;
  animation: appear 1s;
  margin-bottom: 10px;

  ul {
    li {
      background-image: url('../../../commons/svg/icons/right-arrow.svg');
      background-repeat: no-repeat;
      background-position: 0;
      background-size: 13px;
      padding-left: 15px;
      margin: 8px 0;
      cursor: pointer;

      &:hover {
        color: #ef7e29;
      }
    }
  }

  .message-transmitter {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 10px;
    text-align: left;
    color: #666;
    margin-bottom: 14px;
  }

  .time {
    font-size: 9px;
    color: #828282;
    text-align: right;
    margin-bottom: 5px;
  }

  ${(props) =>
    props.mine &&
    `
    text-align: right;

    .message-transmitter {
      text-align: right;
    }
    `};
`;

export const UserName = styled.div<{ hideName: boolean | string }>`
  display: ${(props) => (props.hideName ? 'none' : 'block')};
`;

export const MessageTime = styled.div<{ hideTime: boolean | string; color: string }>`
  font-size: 9px;
  color: ${(props) => props.color};
  text-align: right;
  margin-bottom: 5px;
  display: ${(props) => (props.hideTime ? 'none' : 'block')};
`;

export const MessageContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export const ChatLink = styled.a<{
  backgroundColor: string;
  color: string;
  fontSize: string;
  boxShadow: string;
  borderColor: string;
  hoverOpacity: string;
  textShadow: string;
  border: string;
}>`
  background-color: ${(props) => props.backgroundColor};
  font-family: inherit;
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  padding: 10px 25px;
  border: ${(props) => props.border};
  border-color: ${(props) => props.borderColor};
  border-radius: 6px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.2s ease 0s;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 12px;
  box-shadow: ${(props) => props.boxShadow}
  &:hover {
    opacity: ${(props) => props.hoverOpacity};
  }
`;
