import styled, { css } from 'styled-components';

export const Root = styled.div<{ zIndex: string; alignFrame: string }>`
  position: fixed;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '999999')};
  height: auto;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;

  @media (min-width: 992px) {
    top: initial;
    left: initial;
    right: 30px;
    bottom: 10px;
    width: auto;

    ${(props) =>
      props.alignFrame === 'left' &&
      css`
        right: inherit;
        left: 30px;
      `}
  }
`;
