import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  div#chatbox {

      div {
        line-height: 1.3;
        box-sizing:border-box;
      }

      textarea {
        min-height: 28px;
        height: 28px;
        line-height: initial;
        border: 0;
        margin: 14px 0px 0px 25px;
        padding: 0;
        display: inline-block;
        width: 70%;

        &:focus {
          border: none;
          box-shadow: none;
          outline: 0;
          font-size: inherit;
        }
      }

      img, svg {
        opacity: 1;
      }
    
  }
`;
