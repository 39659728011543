import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 100px;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 1);
  flex-direction: column;
  border-style: solid;
`;

export const CardSearchSkill = styled.div<{ backgroundColor: string; boxShadow: string }>`
  /*   height: 170px; */
  background-color: ${(props) => props.backgroundColor};
  border-radius: 6px;
  shadow-radius: 0px;
  flex-direction: column;
  margin-top: 12px;
  margin-left: 0px;
  margin-right: 8px;
  margin-bottom: 10px;
  display: flex;
  box-shadow: ${(props) => props.boxShadow};

  &:hover {
    transform: scale(1.02);
    transition: all 0.3s;
  }
`;

export const LinkTitle = styled.a<{ fontSize: string }>`
  font-style: normal;
  font-weight: 700;
  font-family: inherit;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  margin-top: 13px;
  margin-left: 17px;
  margin-right: 30px;
`;

export const Text = styled.span<{ fontSize: string }>`
  font-style: normal;
  font-weight: 400;
  font-family: inherit;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  margin-top: 12px;
  margin-left: 17px;
  margin-right: 28px;
  margin-bottom: 12px;
`;

export const CardsContainer = styled.div``;
